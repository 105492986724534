import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType } from '@/Templates/enums/templateEnums';
import { CheckOutProvider } from '@/Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext';
import { Col, Row, Tabs, TabsProps } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PortalGlobalSystemSetting, { IPortalGlobalSystemSettingRef } from './Components/PortalGlobalSystemSetting';
import ShopPreview, { IShopStyleSelectorRef } from './Components/ShopPreview';
import SSLSettings, { SSLSettingsRef } from './Components/SSLSettings';
import { PortalSettingPageContext } from './Contexts/PortalSettingPageContext';
import './PortalSettingPage.css';

// Memoized components
const MemoizedPortalGlobalSystemSetting = React.memo(PortalGlobalSystemSetting);
const MemoizedSSLSettings = React.memo(SSLSettings);

const handleComponentConfigFieldsChange = (fields: Array<{ key: string, label: string, type: string, defaultValue: string }>) => {
    // 处理组件配置字段变化
};

type TabsKey = 'frontEndSetting' | 'portalGlobalSystemSetting' | 'sSLSettings' | 'shopStyle' | 'components' | 'pages' | 'dynamicPages';

const handlePageConfigFieldsChange = (fields: Array<{ key: string, label: string, type: string, defaultValue: string }>) => {
    // 处理页面配置字段变化
};

const PortalSettingPage: React.FC = () => {
    const {
        deviceType,
        merchantId,
        messageApi,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        notificationApi
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [queryParams, setQueryParams] = useSearchParams();
    const abortController = useRef<AbortController | null>(null);
    const portalGlobalSystemSettingRef = useRef<IPortalGlobalSystemSettingRef>(null);
    const sslSettingsRef = useRef<SSLSettingsRef>(null);
    const [activeTabKey, setActiveTabKey] = React.useState<TabsKey>('portalGlobalSystemSetting');
    const [selectedComponentStyle, setSelectedComponentStyle] = useState(0);
    const [selectedPageStyle, setSelectedPageStyle] = useState(0);
    const shopStyleSelectorRef = useRef<IShopStyleSelectorRef>(null);
    const handleTabChange = useCallback((key: TabsKey) => {
        setActiveTabKey(key);
        setQueryParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('tab', key);
            // Clear shopTab when switching away from shopStyle
            if (key !== 'shopStyle') {
                newParams.delete('shopTab');
            }
            return newParams;
        });
    }, []);

    const refreshActiveTab = useMemo(() => {
        return () => {
            abortController.current = new AbortController();
            switch (activeTabKey) {
                case 'portalGlobalSystemSetting':
                    portalGlobalSystemSettingRef.current?.onRefresh();
                    break;
                case 'sSLSettings':
                    sslSettingsRef.current?.onRefresh();
                    break;
                case 'shopStyle':
                    merchantPortalOptionSettingMutation.mutate({ merchantId: merchantId!, signal: abortController.current.signal });
                    merchantPortalStyleSettingsMutation.mutate({ merchantId: merchantId!, signal: abortController.current.signal });
                    shopStyleSelectorRef.current?.onRefresh();
                    break;
            }
        };
    }, [activeTabKey]);

    const items: TabsProps['items'] = useMemo(() => [
        {
            key: 'portalGlobalSystemSetting',
            label: translate('PortalGlobalSystemSetting'),
            children: <MemoizedPortalGlobalSystemSetting
                ref={portalGlobalSystemSettingRef} />,
        },

        {

            key: 'shopStyle',
            label: translate('Shop Style'),
            children:
                <CheckOutProvider
                    translate={translate}
                    merchantId={merchantId!}
                    isLogin={true}
                    tryGetSessionId={() => BigInt(0)}
                    messageApi={messageApi}
                >
                    <ShopPreview
                        navBarHeight={deviceType === DeviceType.Mobile ? 50 : 60}
                        navBarGap={deviceType === DeviceType.Mobile ? 50 : 60}
                        deviceType={deviceType}
                        BASE_PATH={''}
                        messageApi={messageApi}
                        notificationApi={notificationApi}
                        merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
                        merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
                        translate={translate}
                        ref={shopStyleSelectorRef}
                        selectedComponentStyle={selectedComponentStyle}
                        selectedPageStyle={selectedPageStyle}
                        merchantId={merchantId!}
                        onComponentConfigFieldsChange={handleComponentConfigFieldsChange}
                        onPageConfigFieldsChange={handlePageConfigFieldsChange}
                    />
                </CheckOutProvider>
        },
        {
            key: 'sSLSettings',
            label: translate('SSLSettings'),
            children: <MemoizedSSLSettings
                ref={sslSettingsRef} />,
        },
    ], [translate, merchantPortalOptionSettingMutation, merchantPortalStyleSettingsMutation, merchantId]);

    useEffect(() => {
        refreshActiveTab();
    }, [refreshActiveTab]);

    useEffect(() => {
        const tabKey = queryParams.get('tab');
        if (tabKey) {
            setActiveTabKey(tabKey as TabsKey);
        }
    }, [queryParams]);

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current?.abort();
        };
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    items={items}
                    activeKey={activeTabKey}
                    tabPosition={deviceType === DeviceType.Mobile ? 'top' : 'left'}
                    onChange={handleTabChange as (key: string) => void}
                />
            </Col>
        </Row>
    );
};

export default React.memo(PortalSettingPage);