import { IBatchCreateThirdPartyInvoiceOrderRequest } from "@/interfaces/Requests/ThirdPartyInvoice";
import { IOrder } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceSetting } from "@/interfaces/Responses/ThirdPartyInvoice";
import { useBatchCreateThirdPartyInvoiceOrderApi, useGetThirdPartyInvoiceSettingsApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ThirdPartyValuesContext } from "@/lib/contexts/ThirdPartyValuesContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { Button, Col, Collapse, Divider, Empty, Flex, Form, Input, Row, Select } from "antd";
import React, { useContext, useImperativeHandle } from "react";
import { useMutation } from "react-query";
import ECPayInvoiceGroupSelector from "../CustomInoviceGroupSelector.tsx/ECPayInvoiceGroupSelector";

const { Panel } = Collapse;

export interface IInvoiceSelectorProps {
    order: IOrder;
    oninvoiceOrderCreated: () => void;
}
export interface IInvoiceSelectorRef {
    onOpen: () => void;
    onClose: () => void;
}
export interface IInvoiceParametersTemplateRef {
    getMerchantFileds: () => Record<string, string>
}

const InvoiceSelector = React.forwardRef((props: IInvoiceSelectorProps, ref: React.ForwardedRef<IInvoiceSelectorRef | undefined>) => {
    const { translate } = useContext(TranslationContext);
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { merchantFieldsForCreate } = useContext(ThirdPartyValuesContext);
    const templateRef = React.useRef<IInvoiceParametersTemplateRef>();
    const { mutate: getThirdPartyinvoicesApiMutate, isLoading: getThirdPartyinvoicesApiLoading } = useMutation(async () => await useGetThirdPartyInvoiceSettingsApi(), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setThirdPartySettings(response.result || []);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const [thirdPartySettings, setThirdPartySettings] = React.useState<IThirdPartyInvoiceSetting[]>([]);
    const [selectedInvoice, SetSelectedInvoice] = React.useState<IThirdPartyInvoiceSetting | undefined>(undefined);
    const { mutate: createinvoiceOrderMutate, isLoading } = useMutation(async (request: IBatchCreateThirdPartyInvoiceOrderRequest) => await useBatchCreateThirdPartyInvoiceOrderApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    props.oninvoiceOrderCreated && props.oninvoiceOrderCreated();
                }
                else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );
    const inputParametersUI = (selectedinvoice: IThirdPartyInvoiceSetting) => {
        //Custom Grouped Selector
        if (selectedinvoice.providerName.includes('ECPay') || selectedinvoice.providerName.includes('綠界')) {
            return (
                <>
                    <Divider></Divider>
                    <ECPayInvoiceGroupSelector
                        order={props.order}
                        thirdPartyInvoiceSetting={selectedinvoice}
                        ref={templateRef}>
                    </ECPayInvoiceGroupSelector>
                </>
            )
        }
        //Normal
        return (
            <>
                {selectedinvoice.merchantFillFieldsForCreateOrder.length > 0 &&
                    <Divider>{translate('Parameters')}</Divider>
                }
                <Form>
                    {
                        selectedinvoice.merchantFillFieldsForCreateOrder.map((field) => {
                            return (
                                <Form.Item key={field} label={field}>
                                    <Input />
                                </Form.Item>
                            )
                        })
                    }
                    {
                        Object.keys(selectedinvoice.merchantSelectFieldsForCreateOrder).map((key) => {
                            return (
                                <Form.Item key={key} label={key}>
                                    <Select>
                                        {
                                            selectedinvoice.merchantSelectFieldsForCreateOrder[key].map((value: string) =>
                                                <Select.Option key={value} value={value}>{value}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        })
                    }
                </Form>
            </>
        )
    }
    useImperativeHandle(ref, () => ({
        onOpen() {
            getThirdPartyinvoicesApiMutate();
        },
        onClose() {
            SetSelectedInvoice(undefined);
            setThirdPartySettings([]);
        }
    }));

    function handleSubmit() {
        if (selectedInvoice) {
            var merchantFilled = templateRef.current?.getMerchantFileds();
            var request: IBatchCreateThirdPartyInvoiceOrderRequest = {
                orderIds: [props.order.id],
                thirdPartySettingId: selectedInvoice.id,
                frontEndValues: merchantFilled ? merchantFilled : {}
            }
            createinvoiceOrderMutate(request);
        }
    }

    if (getThirdPartyinvoicesApiLoading) {
        return <LoadingComponent />;
    }
    return (
        <Flex justify={'center'}>
            <Row style={{ width: '80%' }}>
                {
                    thirdPartySettings &&
                    <>
                        <Divider>{translate('Providers')}</Divider>
                        <Col span={24}>
                            <Flex justify="center">
                                {
                                    thirdPartySettings.length === 0 &&
                                    <Empty description={translate('No Data')}></Empty>
                                }
                                {
                                    //只顯示會員當時選擇指定的該國家的發票
                                    thirdPartySettings
                                        .filter(invoice => invoice.supportedCountry === props.order.invoiceCountry)
                                        .map((invoice) =>
                                            <Button
                                                key={invoice.id.toString()}
                                                style={invoice === selectedInvoice ? { backgroundColor: 'lightblue' } : {}}
                                                onClick={() => {
                                                    if (invoice === selectedInvoice) {
                                                        SetSelectedInvoice(undefined);
                                                    }
                                                    else {
                                                        SetSelectedInvoice(invoice);
                                                    }
                                                }}>
                                                {invoice.providerName}
                                            </Button>
                                        )
                                }
                            </Flex>
                        </Col>
                    </>
                }
                {
                    selectedInvoice &&
                    <>
                        <Col span={24}>
                            {inputParametersUI(selectedInvoice)}
                            <Flex style={{ margin: '10px' }} justify="center">
                                <Button
                                    type="primary"
                                    loading={isLoading}
                                    onClick={() => {
                                        handleSubmit();
                                    }}>
                                    {translate('Submit')}
                                </Button>
                            </Flex>
                        </Col>
                    </>
                }
            </Row>
        </Flex>
    )
});

export default InvoiceSelector;