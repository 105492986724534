import { ThirdPartyPlatformServiceProvider } from "@/enums/ThirdPartyPlatformService";
import { IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel } from "@/interfaces/Responses/ThirdPartyLogistic";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Empty } from "antd";
import { useContext } from "react";
import LineTemplate from "./LineTeamplate";

export interface SendMessageModalProps {
    setting: IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel;
}
const SendMessageModal = (props: SendMessageModalProps) => {
    const { merchantId } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    switch (props.setting.provider) {
        case ThirdPartyPlatformServiceProvider.Facebook:
            return (
                <div>
                    <p>{translate('Facebook')}</p>
                </div>
            );
        case ThirdPartyPlatformServiceProvider.Line:
            return (
                <LineTemplate setting={props.setting}></LineTemplate>
            );
        default:
            return <Empty description={translate('No Data')}></Empty>
    }
}

export default SendMessageModal;