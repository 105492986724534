import { ThirdPartyPlatformServiceProvider } from '@/enums/ThirdPartyPlatformService';
import { IExecuteRequest } from '@/interfaces/Requests/ThirdPartyPlatform';
import { IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel } from '@/interfaces/Responses/ThirdPartyLogistic';
import { useUploadImageApi } from '@/lib/api/images';
import { useSendGroupMessageApi } from '@/lib/api/thirdPartyPlatformService';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Flex, Form, GetProp, Image, Input, Modal, Tabs, Upload, UploadProps } from 'antd';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import ThirdPartyPlatformServiceProviderInputs from '../Inputs/ThirdPartyPlatformServiceProviderInputs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const { TabPane } = Tabs;
const { TextArea } = Input;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface LineTemplateProps {
    setting: IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel;
}

const LineTemplate: React.FC<LineTemplateProps> = ({ setting }) => {
    const { merchantId, messageApi, portalLink } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [activeTab, setActiveTab] = useState('MessageGroup');
    const [textForm] = Form.useForm();
    const [imageForm] = Form.useForm();
    const [imageUrl, setImageUrl] = useState<string>();
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [isTextFormValid, setIsTextFormValid] = useState(false);

    const { mutate: uploadImage, isLoading: isUploading } = useMutation(
        async (data: FormData) => await useUploadImageApi(data),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    setImageUrl(response.result);
                    setIsImageUploaded(true);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                    setIsImageUploaded(false);
                }
            },
        }
    );

    const { mutate: sendGroupMessage, isLoading: isSending } = useMutation(
        async (request: IExecuteRequest) => await useSendGroupMessageApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success('Send Success');
                    textForm.resetFields();
                    imageForm.resetFields();
                    setImageUrl(undefined);
                    setTitle('');
                    setDescription('');
                    setIsImageUploaded(false);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    if (!setting.values['MessageGroup.Enabled'] || setting.values['MessageGroup.Enabled'] === 'false') {
        return (
            <div style={{
                color: '#ff4d4f',
                textAlign: 'center',
                padding: '20px',
                fontSize: '16px',
                fontWeight: 'bold'
            }}>
                {translate('Setting is not enabled')}
            </div>
        );
    }

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            messageApi.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            messageApi.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleTextPromoSubmit = (values: any) => {
        Modal.confirm({
            title: translate('Confirm'),
            content: translate('Are you sure you want to send') + '?',
            onOk() {
                var frontEndValues = values;
                frontEndValues.RequestHost = window.location.origin;
                const request: IExecuteRequest = {
                    Provider: ThirdPartyPlatformServiceProvider.Line,
                    ModuleName: 'MessageGroup',
                    FrontEndValues: frontEndValues,
                };
                sendGroupMessage(request);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleImageTextPromoSubmit = (values: any) => {
        Modal.confirm({
            title: translate('Confirm'),
            content: translate('Are you sure you want to send') + '?',
            onOk() {
                var frontEndValues = values;
                frontEndValues.RequestHost = window.location.origin;
                frontEndValues.ImageLink = imageUrl;
                frontEndValues.RedirectBtnText = translate('Go shopping');
                frontEndValues.RedirectLink = portalLink;
                delete frontEndValues.image;
                const request: IExecuteRequest = {
                    Provider: ThirdPartyPlatformServiceProvider.Line,
                    ModuleName: 'MessageGroup',
                    FrontEndValues: frontEndValues,
                };
                sendGroupMessage(request);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const customRequest = async ({ file, onSuccess, onError }: any) => {
        try {
            const formData = new FormData();
            formData.append('File', file);
            formData.append('UseageType', 'MerchantPromotion');
            formData.append('ReferenceId', merchantId ? merchantId.toString() : '');
            await uploadImage(formData);
            onSuccess();
        } catch (error) {
            onError(error);
            setIsImageUploaded(false);
        }
    };

    const uploadButton = (
        <div>
            {isUploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>
                {isUploading ? translate('Uploading...') : translate('Upload')}
            </div>
        </div>
    );

    const CardPreview = () => (
        <Card
            hoverable
            style={{
                width: 300, marginTop: 16
            }}
            cover={imageUrl ?
                (<Image alt="preview" src={imageUrl} />)
                :
                (<Flex justify='center'>
                    <Empty />
                </Flex>)
            }
        >
            <Card.Meta title={title} description={description} />
            <div style={{ marginTop: 16 }}>
                <Flex justify='center'>
                    <Button
                        style={{
                            marginRight: 8,
                            border: 'none',
                            boxShadow: 'none'
                        }}
                    >
                        {translate('Go shopping')}
                    </Button>
                </Flex>
            </div>
        </Card>
    );

    const validateTextForm = () => {
        const hasErrors = textForm.getFieldsError().some(({ errors }) => errors.length);
        const isTouched = textForm.isFieldsTouched(['Content'], true);
        setIsTextFormValid(!hasErrors && isTouched);
    };

    const handleTextFormChange = () => {
        validateTextForm();
    };

    const isImageFormValid = () => {
        const hasErrors = imageForm.getFieldsError().some(({ errors }) => errors.length);
        const isTouched = imageForm.isFieldsTouched(['Title', 'Content'], true);
        return !hasErrors && isTouched && isImageUploaded;
    };

    const modules = ThirdPartyPlatformServiceProviderInputs[ThirdPartyPlatformServiceProvider.Line];

    return (
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
            {Object.entries(modules).map(([moduleName, inputs]) => (
                <TabPane tab={translate('Text promotion')} key={moduleName}>
                    <Form
                        form={moduleName === 'MessageGroup' ? textForm : Form.useForm()[0]}
                        onFinish={moduleName === 'MessageGroup' ? handleTextPromoSubmit : undefined}
                        onFieldsChange={moduleName === 'MessageGroup' ? handleTextFormChange : undefined}
                    >
                        {moduleName === 'MessageGroup' && (
                            <>
                                <Form.Item
                                    name="Content"
                                    label={translate('Content')}
                                    rules={[{ required: true, message: translate('is required') }]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                                <Form.Item>
                                    <Flex justify='center'>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={isSending}
                                            disabled={!isTextFormValid}
                                        >
                                            {translate('Send')}
                                        </Button>
                                    </Flex>
                                </Form.Item>
                            </>
                        )}
                    </Form>
                </TabPane>
            ))}
            <TabPane tab={translate('Image promotion')} key="ImagePromotion">
                <Form form={imageForm} onFinish={handleImageTextPromoSubmit}>
                    <Form.Item
                        name="image"
                        label={translate('Upload')}
                        valuePropName="file"
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            customRequest={customRequest}
                        >
                            {imageUrl ? (
                                <LazyLoadImage
                                    src={imageUrl}
                                    effect="blur"
                                    alt="avatar"
                                    width={'100%'}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="Title"
                        label={translate('Title')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Input onChange={(e) => setTitle(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name="Content"
                        label={translate('Content')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <TextArea rows={2} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Item>
                    <Flex style={{ margin: '10px' }} justify='center'>
                        <CardPreview />
                    </Flex>
                    <Form.Item>
                        <Flex justify='center'>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isSending}
                                disabled={isUploading || !isImageFormValid()}
                            >
                                {translate('Send')}
                            </Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </TabPane>
        </Tabs>
    );
};

export default LineTemplate;