export enum LoginTypeEnum {
    /// <summary>
    /// 信箱
    /// </summary>
    Email = "Email",
    /// <summary>
    /// 手機
    /// </summary>
    PhoneNumber = "PhoneNumber",
    /// <summary>
    /// 第三方登入
    /// </summary>
    ThirdPartyLogin = "ThirdPartyLogin",
    /// <summary>
    /// 未知
    /// </summary>
    Unknown = "Unknown"
}

export enum GenderEnum {
    Unset = "Unset",
    Male = "Male",
    Female = "Female"
}

export enum OrderStatus {
    WaitForPaid = 'WaitForPaid',
    Paid = 'Paid',
    WaitForMerchantConfirm = 'WaitForMerchantConfirm',
    WaitForShipment = 'WaitForShipment',
    DuringShipment = 'DuringShipment',
    ShipmentDispute = 'ShipmentDispute',
    WaitForBuyerPickup = 'WaitForBuyerPickup',
    BuyerNotPickup = 'BuyerNotPickup',
    ReturnToLogisticsCenter = 'ReturnToLogisticsCenter',
    BuyerApplyRefund = 'BuyerApplyRefund',
    WaitForMerchantConfirmRefundApplication = 'WaitForMerchantConfirmRefundApplication',
    WaitForReturnShipment = 'WaitForReturnShipment',
    DuringReturnShipment = 'DuringReturnShipment',
    ReturnShipmentDispute = 'ReturnShipmentDispute',
    WaitForMerchantPickupRefund = 'WaitForMerchantPickupRefund',
    MerchantNotPickup = 'MerchantNotPickup',
    WaitForMerchantConfirmRefundItems = 'WaitForMerchantConfirmRefundItems',
    WaitForMerchantRefundAmount = 'WaitForMerchantRefundAmount',
    RefundDone = 'RefundDone',
    RefundUnderDispute = 'RefundUnderDispute',
    Done = 'Done',
    Cancel = 'Cancel',
    Unknow = 'Unknow'
}

export enum FrontEndDisplayType {
    /// <summary>
    /// 不動作
    /// </summary>
    None,
    /// <summary>
    /// 頁面跳轉
    /// </summary>
    PageRedirectURL = 'PageRedirectURL',
    /// <summary>
    /// 頁面FormPost組合打出
    /// </summary>
    PageFormPost = 'PageFormPost',
    /// <summary>
    /// 新開頁面
    /// </summary>
    OpenNewPage = 'OpenNewPage',
    /// <summary>
    /// 顯示Html
    /// </summary>
    Html = 'Html',
    /// <summary>
    /// 印表機ESC/POS
    /// </summary>
    PrinterEscPos = 'PrinterEscPos',
    /// <summary>
    /// 印表機Star Micronics
    /// </summary>
    PrinterStarMicronics = 'PrinterStarMicronics',
}

export enum ThirdPartyInvoiceCreateResponseTypeEnum {
    None = 'None',
    FormPost = 'FormPost',
    Redirect = 'Redirect',
    QRCode = 'QRCode',
    Html = 'Html',
    CallPrintApi = 'CallPrintApi',
    /// <summary>
    /// 印表機ESC/POS 
    /// </summary>
    PrinterEscPos = 'PrinterEscPos',
    /// <summary>
    /// 印表機Star Micronics
    /// </summary>
    PrinterStarMicronics = 'PrinterStarMicronics',
}


export enum MerchantWebsiteUpgradeLevel {
    /// <summary>
    /// 無方案
    /// </summary>
    None = 'None',
    /// <summary>
    /// 試用方案
    /// </summary>
    Tutorial = 'Tutorial',
    /// <summary>
    /// 付費方案 1
    /// </summary>
    Normal_1 = 'Normal_1',
    /// <summary>
    /// 付費方案 2
    /// </summary>
    Normal_2 = 'Normal_2',
    /// <summary>
    /// 付費方案 3
    /// </summary>
    Normal_3 = 'Normal_3',
    /// <summary>
    /// VIP 付費方案1
    /// </summary>
    VIP_1 = 'VIP_1',
    /// <summary>
    /// VIP 付費方案2
    /// </summary>
    VIP_2 = 'VIP_2',
    /// <summary>
    /// 客制方案1
    /// </summary>
    Custom = 'Custom',
}



export enum ThirdPartyLoginProviders {
    Unset,
    Google,
    Facebook,
    Twitter,
    Line,
}

export enum ImageUseageType {

    /// <summary>
    /// 商品封面
    /// </summary>
    ItemCover = 'ItemCover',
    /// <summary>
    /// 商品描述
    /// </summary>
    ItemDescription = 'ItemDescription',
    /// <summary>
    /// 商戶主要跑馬燈 (Carousel)
    /// </summary>
    MerchantMarquee = 'MerchantMarquee',
    MerchantPortalStyle = 'MerchantPortalStyle',
    MerchantLandingPageStyle = 'MerchantLandingPageStyle',
    /// <summary>
    /// 預覽用
    /// </summary>
    ForPreview = 'ForPreview',
    /// <summary>
    /// 系統
    /// </summary>
    System = 'System',
    /// <summary>
    /// 商戶Icon
    /// </summary>
    MerchantIcon = 'MerchantIcon',
    /// <summary>
    /// 商戶瀏覽器Tab Icon
    /// </summary>
    MerchantBrowserTabIcon = 'MerchantBrowserTabIcon',
}

export enum MerchantRoleEnum {
    /// <summary>
    /// 擁有者
    /// </summary>
    Owner = 'Owner',
    /// <summary>
    /// 業務
    /// </summary>
    Sales = 'Sales',
    /// <summary>
    /// 投資人 (看報表)
    /// </summary>
    Investor = 'Investor',
    /// <summary>
    /// 開發者 (客製化代碼異動)
    /// </summary>
    Developer = 'Developer',
    /// <summary>
    /// 系統管理員 (系統設定)
    /// </summary>
    SystemAdmin = 'SystemAdmin',
}


