import { ItemContextProvider } from '@/lib/contexts/ItemContext';
import FacebookCallbackPage from '@/lib/thirdParty/FacebookCallbackPage';
import GoogleCallbackPage from '@/lib/thirdParty/GoogleCallbackPage';
import LineCallbackPage from '@/lib/thirdParty/LineCallbackPage';
import AddItemPage from '@/pages/AddItemPages/AddItemPage';
import DataAnalysisPage from '@/pages/DataAnalysisPage/DataAnalysisPage';
import DisclaimerPage from '@/pages/DisclaimerPages/DisclaimerPages';
import EditItemPage from '@/pages/EditItemPage/EditItemPage';
import PageFormPost from '@/pages/FunctionalDisplayPages/PageFormPost';
import ItemManagePage from '@/pages/ItemManagePages/ItemManagePage';
import LoginPage from '@/pages/LoginPages/LoginPage';
import ProxyLoginPage from '@/pages/LoginPages/ProxyLoginPage';
import MemberManagePage from '@/pages/MemberManagePages/MemberManagePage';
import OrderManagePage from '@/pages/OrderManagePages/OrderManagePage';
import { PortalSettingPageContextProvider } from '@/pages/PortalSettingPage/Contexts/PortalSettingPageContext';
import PortalSettingPage from '@/pages/PortalSettingPage/PortalSettingPage';
import RegisterPage from '@/pages/RegisterPage/RegisterPage';
import SystemManagePage from '@/pages/SystemManagePages/SystemManagePage';
import ThirdPartyPage from '@/pages/ThirdPartyPages/ThirdPartyPage';
import UIDesignPage from '@/pages/UIDesignPages/UIDesignPage';
import UserInfoPage from '@/pages/UserInfoPages/UserInfoPage';
import { Route } from '../interfaces/Router';
import HomePage from "../pages/HomePages/HomePage";
import NotFoundPage from "../pages/NotFoundPages/NotFoundPage";
import { MerchantRoleEnum } from '@/enums/Enums';
import AIFlowDesignPage from '@/pages/AIFlowDesignPage/AIFlowDesignPage';

export interface ICustomRoute extends Route {
    merchantPermissionRoles?: string[]; // MerchantRoleEnum
    index?: number;
    navbarIgnore?: boolean;
    ignoreMerchantSeleted?: boolean;
    ignoreLogin?: boolean;
}

export const routes: { [key: string]: ICustomRoute } = {
    HomePage: {
        path: `/`, element: <HomePage />,
        index: 0,
        ignoreMerchantSeleted: true
    },
    PortalSettingPage: {
        path: `/portalSetting`,
        element:
            <PortalSettingPageContextProvider>
                <PortalSettingPage />
            </PortalSettingPageContextProvider>,
        merchantPermissionRoles: [
            MerchantRoleEnum.Owner,
            MerchantRoleEnum.Sales,
        ],
        index: 1
    },
    DataAnalysisPage: {
        path: `/dataAnalysis`,
        element: <DataAnalysisPage />,
        merchantPermissionRoles: [
            MerchantRoleEnum.Owner,
            MerchantRoleEnum.Sales,
        ],
        index: 2
    },
    ThirdPartyPage: {
        path: `/thirdParty`,
        element: <ThirdPartyPage />,
        merchantPermissionRoles: [
            MerchantRoleEnum.Owner,
            MerchantRoleEnum.Sales,
        ],
        index: 3
    },
    OrderManagePage: {
        path: `/orderManage`,
        element: <OrderManagePage />,
        merchantPermissionRoles: [
            MerchantRoleEnum.Owner,
            MerchantRoleEnum.Sales,
        ],
        index: 4
    },
    ItemManagePage: {
        path: `/itemManage`,
        element: <ItemManagePage />,
        merchantPermissionRoles: [
            MerchantRoleEnum.Owner,
            MerchantRoleEnum.Sales,
        ],
        index: 5
    },
    MemberManagePage: {
        path: `/memberManage`,
        element: <MemberManagePage />,
        merchantPermissionRoles: [
            MerchantRoleEnum.Owner,
            MerchantRoleEnum.Sales,
        ],
        index: 7
    },
    DisclaimerPage: {
        path: `/disclaimer`,
        element: <DisclaimerPage />,
        index: 90,
        ignoreMerchantSeleted: true,
        ignoreLogin: true
    },
    AIFlowDesignPage: {
        path: `/aiFlowDesign`,
        element: <AIFlowDesignPage />,
        merchantPermissionRoles: [
            MerchantRoleEnum.SystemAdmin,
        ],
        index: 6
    },
    SystemManagePage: {
        path: `/systemManage`,
        element: <SystemManagePage />,
        index: 99,
        ignoreMerchantSeleted: true,
        merchantPermissionRoles: [
            MerchantRoleEnum.SystemAdmin,
        ],
    },
    UIDesignPage: {
        path: `/uiDesign`,
        element: <UIDesignPage />,
        index: 99,
        merchantPermissionRoles: [
            MerchantRoleEnum.SystemAdmin,
        ],
        ignoreMerchantSeleted: true
    },
    //Ingore for navbar
    LoginPage: {
        path: `/login`,
        element: <LoginPage />,
        navbarIgnore: true,
        ignoreMerchantSeleted: true,
        ignoreLogin: true
    },
    RegisterPage: {
        path: `/register`,
        element: <RegisterPage />,
        navbarIgnore: true,
        ignoreMerchantSeleted: true,
        ignoreLogin: true
    },
    UserInfoPage: {
        path: `/user-info`,
        element: <UserInfoPage />,
        navbarIgnore: true,
        ignoreMerchantSeleted: true,
    },
    LineLoginPage: {
        path: `/line-login`,
        element: <LoginPage />,
        navbarIgnore: true,
        ignoreMerchantSeleted: true,
        ignoreLogin: true
    },
    PageFormPost: {
        path: `/pageFormPost`,
        element: <PageFormPost />,
        navbarIgnore: true
    },
    NotFoundPage: {
        path: `/*`,
        element: <NotFoundPage />,
        navbarIgnore: true,
        ignoreMerchantSeleted: true,
        ignoreLogin: true
    },
    AddItemPage: {
        path: `/addItem`,
        element:
            <ItemContextProvider>
                <AddItemPage />
            </ItemContextProvider>,
        navbarIgnore: true
    },
    EditItemPage: {
        path: `/editItem`, element:
            <ItemContextProvider>
                <EditItemPage />
            </ItemContextProvider>,
        navbarIgnore: true
    },
    //Third Party Login
    ProxyLoginPage: {
        path: `/proxy-login`,
        element: <ProxyLoginPage />,
        ignoreMerchantSeleted: true,
        ignoreLogin: true,
        navbarIgnore: true
    },
    GoogleCallbackPage: {
        path: `/google-callback`,
        element: <GoogleCallbackPage />,
        ignoreMerchantSeleted: true,
        ignoreLogin: true,
        navbarIgnore: true
    },
    FacebookCallbackPage: {
        path: `/facebook-callback`,
        element: <FacebookCallbackPage />,
        ignoreMerchantSeleted: true,
        ignoreLogin: true,
        navbarIgnore: true
    },
    LineCallbackPage: {
        path: `/line-callback`,
        element: <LineCallbackPage />,
        ignoreMerchantSeleted: true,
        ignoreLogin: true,
        navbarIgnore: true
    }
}

export default routes;