import { CurrencyCode } from '@/enums/CurrecnyCode';
import { useGetItemsByMerchantApi, useGetItemSpecsApi } from '@/lib/api/items';
import { useUpsertPortalStyleApi } from '@/lib/api/merchants';
import { useGetQueryOptionsByMerchantIdApi } from '@/lib/api/portals';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import {
    DeviceType,
    MerchantPortalStyleSettingType,
    MerchantPortalStyleShopComponents,
    MerchantPortalStyleShopPages
} from '@/Templates/enums/templateEnums';
import { ObjectHelper } from '@/Templates/helpers/ObjectComparisonHelper';
import { TimeHelper } from '@/Templates/helpers/TimeHelper';
import {
    IItemSpec,
    IItemViewModel,
    IMemberLogisticOption,
    IMerchantPortalStyleSetting,
    IOperationResultT,
    IPaginationResponse,
    IPortalMerchantThirdPartyPaymentSettingViewModel,
    IPortalQueryItemByMerchantRequest,
    ISearchQueryState,
    IShoppingCartItemViewModel,
    IShoppingCartViewModel,
    ITempShoppingCartViewModel
} from '@/Templates/interfaces/templatesInterfaces';
import { FooterTemplateProps } from '@/Templates/Shop/Components/Footer/Template_0';
import { useCheckOut } from '@/Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext';
import {
    FullPageLoadingTemplateProps,
    ItemCardTemplateProps,
    ItemDetailPageTemplateProps,
    NavBarTemplateProps,
    PagesTemplateProps,
    ShoppingCartPageTemplateProps,
    TemplateProps,
} from '@/Templates/TemplateProps';
import { Button, Col, Drawer, Form, List, Row, Select, Tabs, Typography } from 'antd';
import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import GlobalComponentPreview from './GlobalComponentPreview';
import DynamicPagePreview from './DynamicPagePreview';
import PagePreview from './PagePreview';
import { useBatchAddItemsToShoppingCartApi, useGetPaymentMainTypesApi } from '@/Templates/lib/apis';
import { useSearchParams } from 'react-router-dom';
import { useBatchRemoveItemsFromShoppingCartApi } from '@/Templates/lib/apis';
import { useBatchRemoveItemsFromTempShoppingCartApi } from '@/Templates/lib/apis';

const { Title, Text } = Typography;

export interface ShopPreviewProps extends TemplateProps {
    navBarHeight: number;
    navBarGap: number;
    selectedComponentStyle: number;
    selectedPageStyle: number;
    onComponentConfigFieldsChange: (
        fields: Array<{ key: string; label: string; type: string; defaultValue: string }>
    ) => void;
    onPageConfigFieldsChange: (
        fields: Array<{ key: string; label: string; type: string; defaultValue: string }>
    ) => void;
}

export interface IShopStyleSelectorRef {
    onRefresh: () => void;
}

// 預設測試商品
const defaultTestItem = (translate: (key: string) => string): IItemViewModel => {
    return {
        id: BigInt(1),
        name: `${translate('Test')} ${translate('Item')} - 1`,
        title: `${translate('Test')} ${translate('Item')} ${translate('Title')}`,
        briefly: `${translate('Test')} ${translate('Item')} ${translate('Briefly')}`,
        description: `${translate('Test')} ${translate('Item')} ${translate('Description')}`,
        createdDate: new Date().toISOString(),
        alterDate: new Date().toISOString(),
        isDelete: false,
        coverImages: [
            'https://cdn.domigo-digital.com/system/TestItem.png',
            'https://cdn.domigo-digital.com/system/BackgroundImage1.jpg',
            'https://cdn.domigo-digital.com/system/None.png',
        ],
        itemTags: [
            {
                id: BigInt(1),
                merchantId: BigInt(1),
                name: '测试标签',
                isDeleted: false,
            },
        ],
        minPrice: 99.99,
        maxPrice: 199.99,
        isOnShelf: true,
        isSoldOut: false
    }
}
// 預設測試規格
const defaultTestItemSpecs = (translate: (key: string) => string): IItemSpec[] => [
    {
        id: BigInt(1),
        name: `${translate('Test')} ${translate('Spec')} ${translate('Unmanaged')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 100,
        fixedPrice: 100,
        costPrice: 100,
        isManageStockAmount: false,
        stockAmount: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 0,
    },
    {
        id: BigInt(2),
        name: `${translate('Test')} ${translate('Spec')} ${translate('Normal')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 200,
        fixedPrice: 200,
        costPrice: 100,
        isManageStockAmount: true,
        stockAmount: 100,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 1,
    },
    {
        id: BigInt(3),
        name: `${translate('Test')} ${translate('Spec')} ${translate('SoldOut')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 200,
        fixedPrice: 200,
        costPrice: 200,
        isManageStockAmount: true,
        stockAmount: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 2,
    }
];

interface StyleState {
    style: number;
    configFields: Array<any>;
    styleConfig: Record<string, string>;
}

const PreviewContainer = styled.div<{ containerwidth: number, previewwidth: string; previewdevicetype: DeviceType, windowdevicetype: DeviceType }>`
  border: 1px solid #ccc;
  overflow-y: scroll;
  overflow-x: hidden; 
  display: flex;
  align-items: flex-start; // 改為靠上對齊
  justify-content: center;
  width: ${props => `${props.previewwidth}`};
  max-width: ${props => props.windowdevicetype === DeviceType.Desktop ? '100%' : '90%'};
  margin: 0 auto;
  position: relative;
  height: 75vh; // 設置固定高度
  max-height: 80vh;

  & > div {
    width: ${props => props.previewwidth};
    height: 100%;
    transform-origin: top center; // 改為從頂部中縮放
    transition: transform 0.3s ease;
    position: absolute;
    top: 0; // 靠上對齊
    left: 50%;
  }

  ${props => {
        const previewWidth = props.previewwidth;
        const previewWidthNumber = parseInt(previewWidth.replace('px', ''));
        console.log('previewWidthNumber', previewWidthNumber, props.containerwidth);
        // 計算縮放比例
        const scale = Math.min(props.containerwidth / previewWidthNumber, 1);
        return `
      & > div {
        transform: translateX(-50%) scale(${scale});
      }
    `;
    }}

  /* 定義自定義容器查詢 */
  container-type: inline-size;
  container-name: preview-container;

  /* 添加內部容器以確保內容可以水平滾動 */
  & > div {
    min-width: 100%;
    width: ${props => props.previewwidth};
  }

  /* 自定義滾動條樣式 */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

type PreviewTabs = 'GlobalComponentPreview' | 'DynamicPagePreview' | 'PagePreview';

const ShopPreview = forwardRef<IShopStyleSelectorRef, ShopPreviewProps>(({
    merchantId,
    messageApi,
    translate,
    navBarHeight,
    navBarGap
}, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<PreviewTabs>(() => {
        const tab = searchParams.get('shopTab');
        return tab ? tab as PreviewTabs : 'GlobalComponentPreview';
    });
    const [seleceTestDataDrawerVisible, setSeleceTestDataDrawerVisible] = useState(false);
    const [merchantItems, setMerchantItems] = useState<IItemViewModel[]>([]);
    const [testItem, setTestItem] = useState<IItemViewModel>(defaultTestItem(translate));
    const [testItemSpecs, setTestItemSpecs] = useState<IItemSpec[]>(defaultTestItemSpecs(translate));
    const abortController = useRef<AbortController | null>(null);
    const [newDynamicPageName, setNewDynamicPageName] = useState('');
    var queryRequest: IPortalQueryItemByMerchantRequest = {
        merchantId,
        categoryIds: [],
        isOnShelf: true
    };
    const [testPaginationItems, setTestPaginationItems] = useState<IPaginationResponse<IItemViewModel[]>>({
        data: [testItem],
        totalCount: 1,
        totalPage: 1
    });
    const [testSearchQueryState, setTestSearchQueryState] = useState<ISearchQueryState>({
        options: {
            isSuccess: true,
            result: {
                itemTags: [],
                categories: [],
            }
        },
        form: undefined,
        setRequest: function (request: any): void {

        },
        search: function (request: any): void {

        },
        loading: false,
        onFormValuesChange: (changedValues: any, allValues: any) => {
            queryRequest = {
                merchantId: merchantId,
                fuzzingName: allValues.fuzzingName,
                categoryIds: allValues.categoryIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                extraItemTagIds: allValues.itemTagIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                extraItemIds: allValues.extraItemIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
            };
        },
        onSearch: function (): void {
            useGetItemsByMerchantApi(queryRequest).then((res) => {
                if (res.result) {
                    if (testPaginationItems.data.length !== res.result.data.length + 1) {
                        var newData = [...res.result.data, defaultTestItem(translate)];
                        setTestPaginationItems({
                            data: newData,
                            totalCount: res.result.totalCount + 1,
                            totalPage: res.result.totalPage,
                        });
                    }
                }
            });
        },
        isLoading: false
    });
    const {
        deviceType: windowdevicetype,
        notificationApi,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation
    } = useContext(GlobalContext);
    const {
        selectedItems,
        setSelectedItems,
        currentStep,
        setCurrentStep,
        userWantReservePay,
        setSelectedLogistic,
        setUserWantReservePay,
        logisticValues,
        setLogisticValues,
        receiverInfos,
        setReceiverInfos,
        nextStep,
        prevStep,
        handleSelectAll,
        handleSelectItem,
        isOnlinePayment,
        setIsOnlinePayment,
        paymentValues,
        setPaymentValues,
        note,
        setNote,
        isAddingStoreAddress,
        isDeletingLogisticOption,
        invoiceValues,
        invoiceValidate,
        setInvoiceValues,
        setInvoiceValidate,
        handleAddMemberLogisticOption,
        handleDeleteMemberLogisticOption,
        handleAddStoreAddressOption,
        sendPaymentRequest
    } = useCheckOut();
    const [previewdevicetype, setPreviewDeviceType] = useState<DeviceType>(
        DeviceType.Desktop
    );
    const previewContainerRef = useRef<HTMLDivElement>(null);
    const [containerwidth, setContainerWidth] = useState<number>(0);
    const [ComponentTemplateComponent, setComponentTemplateComponent] =
        useState<React.ComponentType<TemplateProps> | null>(null);
    const [PageTemplateComponent, setPageTemplateComponent] =
        useState<React.ComponentType<PagesTemplateProps> | null>(null);
    const [mockComponentProps, setMockComponentProps] = useState<any>(null);
    const [mockPageProps, setMockPageProps] = useState<any>(null);
    const [mockDynamicPageProps, setMockDynamicPageProps] = useState<any>(null);
    const [selectedPageType, setSelectedPageType] =
        useState<MerchantPortalStyleShopPages>(
            MerchantPortalStyleShopPages.LandingPage
        );
    const [selectedComponentType, setSelectedComponentType] =
        useState<MerchantPortalStyleShopComponents>(
            MerchantPortalStyleShopComponents.NavBar
        );
    const [selectedDynamicPageType, setSelectedDynamicPageType] =
        useState<IMerchantPortalStyleSetting>();
    const [refreshComponentCount, setRefreshComponentCount] = useState(0);
    const [refreshPageCount, setRefreshPageCount] = useState(0);
    const [styleState, setStyleState] = useState<{
        component: StyleState;
        page: StyleState;
    }>({
        component: {
            style: 0,
            configFields: [],
            styleConfig: {},
        },
        page: {
            style: 0,
            configFields: [],
            styleConfig: {},
        },
    });
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpec | null>(
        testItemSpecs[0]
    );
    const [testshoppingCartData, setTestshoppingCartData] = useState<IShoppingCartViewModel | ITempShoppingCartViewModel | undefined>(undefined);
    const [purchaseQuantity, setPurchaseQuantity] = useState<number>(0);
    const [form] = Form.useForm();
    const [selectedItemId, setSelectedItemId] = useState<string>('default');
    const shoppingCartDataMutation = useMutation(
        ["GetShoppingCartApi", merchantId.toString(), true],
        async () => {
            return {
                isSuccess: true,
                result: testshoppingCartData
            } as IOperationResultT<IShoppingCartViewModel>;
        }
    );
    const batchDeleteShoppingCartItemsMutation = useMutation({
        mutationFn: async (items: IShoppingCartItemViewModel[]) => {
            try {
                return {
                    isSuccess: true,
                    result: [

                    ] as any
                } as IOperationResultT<IShoppingCartViewModel>;
            } catch (error) {
                console.error('購物車項目刪除失敗:', error);
                throw error;
            }
        },
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Delete Success'));
            } else {
                messageApi.error(translate('Delete Failed'));
            }
        },
        onError: (error) => {
            messageApi.error(translate('Delete Failed'));
            console.error('購物車項目刪除錯誤:', error);
        }
    });
    const batchAddShoppingCartItemsMutation = useMutation({
        mutationFn: async (items: IShoppingCartItemViewModel[]) => {
            return {
                isSuccess: true,
                result: [] as any
            } as IOperationResultT<IShoppingCartViewModel>;
        }
    });
    const merchantPortalPaymentSettingsMutation = useMutation(
        ['getMerchantThirdPartyPaymentSettingViewModels', merchantId],
        async (params: { merchantId: BigInt; signal?: AbortSignal | undefined }) => {
            return {
                isSuccess: true,
                result: [] as any
            } as IOperationResultT<IPortalMerchantThirdPartyPaymentSettingViewModel[]>;
        }
    );
    const paymentMainTypesMutation = useMutation(
        'getPaymentMainTypes',
        async (params: { signal?: AbortSignal | undefined }) => {
            return {
                isSuccess: true,
                result: [] as string[]
            } as IOperationResultT<string[]>;
        }
    );
    const memberLogisticOptionsMutation = useMutation(
        async (params: { isLogin: boolean, signal?: AbortSignal | undefined }) => {
            return {
                isSuccess: true,
                result: [] as IMemberLogisticOption[]
            } as IOperationResultT<IMemberLogisticOption[]>;
        }
    );
    const handleAddToShoppingCartMutation = useMutation(
        async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    );

    // 將 baseMockTemplateProps 改為 useState
    const [baseMockTemplateProps, setBaseMockTemplateProps] = useState<TemplateProps>({
        navBarHeight,
        navBarGap,
        deviceType: previewdevicetype,
        isPreviewMode: true,
        merchantId,
        translate,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        messageApi,
        notificationApi,
        BASE_PATH: '/base-path',
        config: {}
    });

    const loadComponentTemplate = useCallback(async () => {
        try {
            const componentModule = await import(
                `@/Templates/Shop/Components/${selectedComponentType}/Template_${styleState.component.style}.tsx`
            );
            setComponentTemplateComponent(() => componentModule.default);

            let newFields = [];
            if (componentModule.getConfigFields) {
                newFields = componentModule.getConfigFields();
            }
            const merchantAlreadyFilledConfigs = merchantPortalStyleSettingsMutation.data?.result?.find(
                (x) =>
                    x.type === MerchantPortalStyleSettingType.Components &&
                    x.name === selectedComponentType &&
                    x.style === styleState.component.style
            );
            setStyleState((prevState) => ({
                ...prevState,
                component: {
                    ...prevState.component,
                    configFields: newFields,
                    styleConfig: merchantAlreadyFilledConfigs?.styleSetting || {},
                },
            }));
            setBaseMockTemplateProps(prev => ({
                ...prev,
                config: merchantAlreadyFilledConfigs?.styleSetting || {},
            }));
            setRefreshComponentCount((prev) => prev + 1);
        } catch (error) {
            console.error('加载组件模板失败:', error);
            setComponentTemplateComponent(null);
            setStyleState((prevState) => ({
                ...prevState,
                component: {
                    ...prevState.component,
                    configFields: [],
                    styleConfig: {},
                },
            }));
            setBaseMockTemplateProps(prev => ({
                ...prev,
                config: {},
            }));
        }
    }, [
        selectedComponentType,
        styleState.component.style,
        merchantPortalStyleSettingsMutation,
    ]);

    const loadPageTemplate = useCallback(async () => {
        try {
            const pageModule = await import(
                `@/Templates/Shop/Pages/${selectedPageType}/Template_${styleState.page.style}.tsx`
            );
            setPageTemplateComponent(() => pageModule.default);

            let newFields = [];
            if (pageModule.getConfigFields) {
                newFields = pageModule.getConfigFields();
            }

            const merchantAlreadyFilledConfigs = merchantPortalStyleSettingsMutation.data?.result?.find(
                (x) =>
                    x.type === MerchantPortalStyleSettingType.Pages &&
                    x.name === selectedPageType &&
                    x.style === styleState.page.style
            );

            setStyleState((prevState) => ({
                ...prevState,
                page: {
                    ...prevState.page,
                    configFields: newFields,
                    styleConfig: merchantAlreadyFilledConfigs?.styleSetting || {},
                },
            }));
            setBaseMockTemplateProps(prev => ({
                ...prev,
                config: merchantAlreadyFilledConfigs?.styleSetting || {},
            }));
            setRefreshPageCount((prev) => prev + 1);
        } catch (error) {
            console.error('加载页面模板失败:', error);
            setPageTemplateComponent(null);
            setStyleState((prevState) => ({
                ...prevState,
                page: {
                    ...prevState.page,
                    configFields: [],
                    styleConfig: {},
                },
            }));
            setBaseMockTemplateProps(prev => ({
                ...prev,
                config: {},
            }));
        }
    }, [
        selectedPageType,
        styleState.page.style,
        merchantPortalStyleSettingsMutation,
    ]);

    const loadDynamicPageTemplate = useCallback(async () => {
        try {
            const pageModule = await import(
                `@/Templates/Shop/Pages/${selectedPageType}/Template_${styleState.page.style}.tsx`
            );
        } catch (error) {
            console.error('加载动态页面模板失败:', error);
        }
    }, [selectedPageType, styleState.page.style]);

    const loadMockComponentProps = useCallback(() => {
        try {
            switch (selectedComponentType) {
                case MerchantPortalStyleShopComponents.NavBar:
                    const navBarProps: NavBarTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                        merchantPortalOptionSettingMutation,
                        isHideNavBar: false,
                        shoppingCartItemCount: 0,
                        isLogin: false,
                        previewContainer: previewContainerRef.current
                            ? previewContainerRef.current
                            : undefined,
                    };
                    setMockComponentProps({ ...navBarProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopComponents.ItemCard:
                    const itemCardProps: ItemCardTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                        item: testItem,
                        isloading: false,
                    };
                    setMockComponentProps({
                        ...itemCardProps,
                        deviceType: previewdevicetype,
                        isCentered: true
                    });
                    break;
                case MerchantPortalStyleShopComponents.Marquee:
                    setMockComponentProps({
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                        isCentered: false
                    });
                    break;
                case MerchantPortalStyleShopComponents.FullPageLoading:
                    setMockComponentProps({
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                        isFullPageLoading: true,
                        isCentered: false
                    });
                    break;
                case MerchantPortalStyleShopComponents.Footer:
                    const footerProps: FooterTemplateProps = {
                        ...baseMockTemplateProps,
                        deviceType: previewdevicetype,
                        config: styleState.component.styleConfig,
                        isHideFooter: false,
                        buildTime: TimeHelper.formatUtcStringToLocal(new Date().toISOString()),
                    };
                    setMockComponentProps({ ...footerProps, isCentered: false });
                    break;
                default:
                    setMockComponentProps(null);
            }
        } catch (error) {
            console.error('加载组件属性失败:', error);
            setMockComponentProps(null);
        }
    }, [
        selectedComponentType,
        previewContainerRef,
        previewdevicetype,
        styleState.component.styleConfig,
    ]);

    const loadMockPageProps = useCallback(async () => {
        try {
            switch (selectedPageType) {
                case MerchantPortalStyleShopPages.LandingPage:
                    const landingPageProps: PagesTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        deviceType: previewdevicetype,
                        items: testPaginationItems,
                        queryState: testSearchQueryState,
                    };
                    setMockPageProps({ ...landingPageProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopPages.HomePage:
                    useGetItemsByMerchantApi(queryRequest).then((res) => {
                        if (res.result) {
                            if (testPaginationItems.data.length !== res.result.data.length + 1) {
                                var newData = [...res.result.data, defaultTestItem(translate)];
                                setTestPaginationItems({
                                    data: newData,
                                    totalCount: res.result.totalCount + 1,
                                    totalPage: res.result.totalPage,
                                });
                            }
                        }
                    });
                    const homePageProps: PagesTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        items: testPaginationItems,
                        deviceType: previewdevicetype,
                    };
                    setMockPageProps({ ...homePageProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopPages.ItemDetailPage:
                    const itemDetailPageProps: ItemDetailPageTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        item: testItem,
                        deviceType: previewdevicetype,
                        itemSpecs: testItemSpecs,
                        selectedItemSpec: selectedItemSpec,
                        purchaseQuantity: purchaseQuantity,
                        handleSelectChange: function (value: string): void {
                            var spec = testItemSpecs.find((x) => x.id.toString() === value);
                            setSelectedItemSpec(
                                spec || null
                            );
                        },
                        setPurchaseQuantity: function (value: number): void {
                            setPurchaseQuantity(value);
                        },
                        handleAddToShoppingCartMutation,
                        shoppingCartItemCount: 0,
                        isLoading: false,
                    };
                    setMockPageProps({ ...itemDetailPageProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopPages.SearchPage:
                    const queryOptions = await useGetQueryOptionsByMerchantIdApi(merchantId);
                    //check queryOptions is not same as testSearchQueryState.options
                    if (!ObjectHelper.areObjectsEqual(queryOptions, testSearchQueryState.options)) {
                        setTestSearchQueryState(prevState => ({
                            ...prevState,
                            options: queryOptions
                        }));
                    }
                    const searchPageProps: PagesTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        items: testPaginationItems,
                        queryState: testSearchQueryState,
                        deviceType: previewdevicetype,
                    };
                    setMockPageProps({ ...searchPageProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopPages.ShoppingCartPage:
                    const shoppingCartPageProps: ShoppingCartPageTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        shoppingCartDataMutation,
                        deviceType: previewdevicetype,
                        isLogin: false,
                        tryGetSessionId: function (): BigInt {
                            return BigInt(0);
                        },
                        shoppingCartItemCount: 0,
                        batchDeleteShoppingCartItemsMutation,
                        batchAddShoppingCartItemsMutation,
                        selectedPayment: undefined,
                        setSelectedPayment: function (payment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined): void {
                            return;
                        },
                        logisticsMainTypes: [],
                        logisticMethods: [],
                        selectedLogistic: undefined,
                        setSelectedLogistic: setSelectedLogistic,
                        selectedMemberLogisticOption: undefined,
                        setSelectedMemberLogisticOption: function (): void {
                            return;
                        },
                        memberSelectedParameters: {},
                        setMemberSelectedParameters: function (key: string, value: string): void {
                            return;
                        },
                        logisticValues: logisticValues,
                        setLogisticValues: setLogisticValues,
                        userWantReservePay: userWantReservePay,
                        setUserWantReservePay: setUserWantReservePay,
                        receiverInfos: receiverInfos,
                        setReceiverInfos: setReceiverInfos,
                        sendGAEvent: function (eventName: string, eventData: any): void {
                            return;
                        },
                        getCountrySelectionDisplayName: function (country: string): string {
                            return '';
                        },
                        selectedItems: selectedItems,
                        setSelectedItems: setSelectedItems,
                        currentStep: currentStep,
                        setCurrentStep: setCurrentStep,
                        nextStep: nextStep,
                        prevStep: prevStep,
                        handleSelectAll: handleSelectAll,
                        handleSelectItem: handleSelectItem,
                        isAddingStoreAddress,
                        setNote,
                        setIsOnlinePayment,
                        isDeletingLogisticOption,
                        isOnlinePayment,
                        invoiceValues,
                        invoiceValidate,
                        note: '',
                        setInvoiceValues,
                        setInvoiceValidate,
                        paymentValues,
                        setPaymentValues,
                        sendPaymentRequest,
                        handleAddMemberLogisticOption,
                        handleDeleteMemberLogisticOption,
                        handleAddStoreAddressOption,
                        merchantPortalPaymentSettingsMutation,
                        paymentMainTypesMutation,
                        memberLogisticOptionsMutation,
                    };
                    setMockPageProps({ ...shoppingCartPageProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopPages.ContactUsPage:
                    const contactUsPageProps: PagesTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        deviceType: previewdevicetype,
                    };
                    setMockPageProps({ ...contactUsPageProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopPages.PrivacyPolicyPage:
                    const PrivacyPolicyPageProps: PagesTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        deviceType: previewdevicetype,
                    };
                    setMockPageProps({ ...PrivacyPolicyPageProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopPages.TermsOfServicePage:
                    const disclaimerPageProps: PagesTemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.page.styleConfig,
                        deviceType: previewdevicetype,
                    };
                    setMockPageProps({ ...disclaimerPageProps, isCentered: false });
                    break;
                default:
                    setMockPageProps(null);
            }
        } catch (error) {
            console.error('加载页面属性失败:', error);
            setMockPageProps(null);
        }
    }, [
        selectedPageType,
        styleState.page.styleConfig,
        testPaginationItems,
        testSearchQueryState,
        selectedItemSpec,
        purchaseQuantity,
    ]);


    const loadMerchantItems = useCallback(async () => {
        const res = await useGetItemsByMerchantApi(queryRequest);
        if (res.result) {
            setMerchantItems([...res.result.data]);
        }
    }, [queryRequest]);

    const loadItemSpecs = useCallback(async (itemId: string) => {
        if (itemId === 'default') {
            setTestItemSpecs(defaultTestItemSpecs(translate));
        } else {
            const res = await useGetItemSpecsApi(merchantId, BigInt(itemId));
            if (res.result) {
                setTestItemSpecs(res.result);
            }
        }
    }, [merchantId, translate]);

    const handleTestItemSelect = async (value: string) => {
        setSelectedItemId(value);
        if (value === 'default') {
            setTestItem(defaultTestItem(translate));
            setTestItemSpecs(defaultTestItemSpecs(translate));
        } else {
            const selectedItem = merchantItems.find(item => item.id.toString() === value);

            if (selectedItem) {
                setTestItem(selectedItem);
                await loadItemSpecs(value);
            }
        }
    };

    const showSeleceTestDataDrawer = () => {
        return (
            <div >
                <Button onClick={() => setSeleceTestDataDrawerVisible(true)}>
                    {translate('Select Test Data')}
                </Button>
                <Drawer
                    placement='left'
                    title={translate("Select Test Data")}
                    onClose={() => setSeleceTestDataDrawerVisible(false)}
                    open={seleceTestDataDrawerVisible}
                    width={400}
                >
                    <Form form={form} layout="vertical">
                        <Form.Item name="testItem" label={translate("Item")}>
                            <Select
                                style={{ width: '100%' }}
                                placeholder={translate("Select")}
                                onChange={handleTestItemSelect}
                                value={selectedItemId}
                                defaultValue={'default'}
                            >
                                <Select.Option value="default">
                                    {translate(defaultTestItem(translate).name)} (Default)
                                </Select.Option>
                                {merchantItems.map(item => (
                                    <Select.Option key={item.id.toString()} value={item.id.toString()}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                    <Title level={5}>{translate("Specs")}</Title>
                    <List
                        dataSource={testItemSpecs}
                        renderItem={spec => (
                            <List.Item>
                                <List.Item.Meta
                                    title={spec.name}
                                    description={
                                        <>
                                            <Text>{`${translate("Price")}: ${spec.sellPrice} ${spec.currency}`}</Text>
                                            <br />
                                            <Text>{`${translate("Stock")}: ${spec.isManageStockAmount ? spec.stockAmount : translate("Not Managed")}`}</Text>
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Drawer>
            </div>
        );
    };

    const updateWidth = useCallback(() => {
        // 使用 requestAnimationFrame 確保 DOM 已更新
        requestAnimationFrame(() => {
            if (previewContainerRef.current) {
                const rect = previewContainerRef.current.getBoundingClientRect();
                if (rect.width > 0) {  // 添加寬度檢查
                    setContainerWidth(rect.width);
                }
            }
        });
    }, []);

    const handleStyleChange = (type: 'component' | 'page', value: number) => {
        setStyleState((prevState) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                style: value,
            },
        }));
    };

    const handleConfigChange = (
        type: 'component' | 'page',
        key: string,
        value: string
    ) => {
        setStyleState((prevState) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                styleConfig: {
                    ...prevState[type].styleConfig,
                    [key]: value.toString(),
                },
            },
        }));
    };

    // 预览设备类型切换
    const handlePreviewDeviceChange = (type: DeviceType) => {
        setPreviewDeviceType(type);
    };

    const getPreviewWidth = () => {
        switch (previewdevicetype) {
            case DeviceType.Mobile:
                return '375px';
            case DeviceType.LapTop:
                return '768px';
            case DeviceType.Desktop:
            default:
                return '1024px';
        }
    };

    useEffect(() => {
        setBaseMockTemplateProps(prev => ({
            ...prev,
            deviceType: previewdevicetype,
        }));
    }, [
        previewdevicetype
    ]);

    useEffect(() => {
        loadComponentTemplate();
    }, [
        activeTab,
        selectedComponentType,
        styleState.component.style,
        loadComponentTemplate,
    ]);

    useEffect(() => {
        loadMockComponentProps();
    }, [
        activeTab,
        selectedComponentType,
        styleState.component.style,
        previewdevicetype,
        styleState.component.styleConfig,
        refreshComponentCount,
        testItemSpecs,
        testItem,
        loadMockComponentProps,
    ]);

    useEffect(() => {
        loadPageTemplate();
    }, [
        activeTab,
        selectedPageType,
        styleState.page.style,
        loadPageTemplate
    ]);

    useEffect(() => {
        loadMockPageProps();
    }, [
        activeTab,
        selectedPageType,
        styleState.page.style,
        previewdevicetype,
        styleState.page.styleConfig,
        refreshPageCount,
        testItemSpecs,
        testItem,
        loadMockPageProps,
        selectedItemSpec,
        purchaseQuantity,
    ]);

    useEffect(() => {
        loadDynamicPageTemplate();
    }, [
        activeTab,
        selectedPageType,
        styleState.page.style,
        loadDynamicPageTemplate
    ]);


    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            loadComponentTemplate();
            loadPageTemplate();
            loadMockComponentProps();
            loadDynamicPageTemplate();
            loadMockPageProps();
            loadMerchantItems();
        },
    }));

    useEffect(() => {
        // 初始化時延遲執行一次
        const initTimer = setTimeout(updateWidth, 100);

        // 添加 resize 監聽
        window.addEventListener('resize', updateWidth);

        // 創建 ResizeObserver
        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        // 延遲觀察元素
        const observeTimer = setTimeout(() => {
            if (previewContainerRef.current) {
                resizeObserver.observe(previewContainerRef.current);
            }
        }, 100);

        loadMerchantItems();

        return () => {
            clearTimeout(initTimer);
            clearTimeout(observeTimer);
            window.removeEventListener('resize', updateWidth);
            resizeObserver.disconnect();
        };
    }, [activeTab, updateWidth]); // 添加 activeTab 作為依賴

    useEffect(() => {
        setSelectedItemId('default');
        setTestItem(defaultTestItem(translate));
        setTestItemSpecs(defaultTestItemSpecs(translate));
    }, [translate]);

    // 監聽 merchantPortalStyleSettings 變化
    useEffect(() => {
        // 重新加載組件模板
        loadComponentTemplate();
        // 重新加載頁面模板 
        loadPageTemplate();
        // 重新加載組件屬性
        loadMockComponentProps();
        // 重新加載頁面屬性
        loadMockPageProps();
    }, [merchantPortalStyleSettingsMutation]);

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current?.abort();
        };
    }, []);

    const items = [
        {
            key: 'GlobalComponentPreview',
            label: translate('Components'),
            children: (
                <GlobalComponentPreview
                    containerwidth={containerwidth}
                    previewdevicetype={previewdevicetype}
                    windowdevicetype={windowdevicetype}
                    previewContainerRef={previewContainerRef}
                    selectedComponentType={selectedComponentType}
                    setSelectedComponentType={setSelectedComponentType}
                    ComponentTemplateComponent={ComponentTemplateComponent}
                    mockComponentProps={mockComponentProps}
                    styleState={styleState}
                    handleStyleChange={handleStyleChange}
                    handleConfigChange={handleConfigChange}
                    handlePreviewDeviceChange={handlePreviewDeviceChange}
                    getPreviewWidth={getPreviewWidth}
                    showSeleceTestDataDrawer={showSeleceTestDataDrawer}
                    translate={translate}
                    abortController={abortController}
                />
            ),
        },
        {
            key: 'PagePreview',
            label: translate('Pages'),
            children: (
                <PagePreview
                    containerwidth={containerwidth}
                    previewdevicetype={previewdevicetype}
                    windowdevicetype={windowdevicetype}
                    previewContainerRef={previewContainerRef}
                    selectedPageType={selectedPageType}
                    setSelectedPageType={setSelectedPageType}
                    PageTemplateComponent={PageTemplateComponent}
                    mockPageProps={mockPageProps}
                    styleState={styleState}
                    handleStyleChange={handleStyleChange}
                    handleConfigChange={handleConfigChange}
                    handlePreviewDeviceChange={handlePreviewDeviceChange}
                    getPreviewWidth={getPreviewWidth}
                    showSeleceTestDataDrawer={showSeleceTestDataDrawer}
                    translate={translate}
                    abortController={abortController}
                />
            ),
        },
        {
            key: 'DynamicPagePreview',
            label: translate('Dynamic Pages'),
            children: (
                <DynamicPagePreview
                    containerwidth={containerwidth}
                    previewdevicetype={previewdevicetype}
                    windowdevicetype={windowdevicetype}
                    previewContainerRef={previewContainerRef}
                    handlePreviewDeviceChange={handlePreviewDeviceChange}
                    getPreviewWidth={getPreviewWidth}
                    translate={translate}
                    showSeleceTestDataDrawer={showSeleceTestDataDrawer}
                />
            ),
        }
    ];

    if (merchantPortalStyleSettingsMutation.isLoading) {
        return <LoadingComponent />;
    }

    const handleTabChange = (key: string) => {
        setActiveTab(key as PreviewTabs);
        // Update URL while preserving the main tab parameter
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('shopTab', key);
            return newParams;
        });
    };

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    destroyInactiveTabPane={true}
                    items={items}
                    activeKey={activeTab}
                    onChange={handleTabChange}
                />
            </Col>
        </Row>
    );
});

export default ShopPreview;
