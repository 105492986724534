import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { IDynamicComponent, IMerchantPortalStyleSetting } from "@/Templates/interfaces/templatesInterfaces";
import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Collapse, Flex, Modal, Row, Spin } from "antd";
import { Suspense, useContext, useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import type {
    DroppableProvided,
    DroppableStateSnapshot,
    DraggableProvided,
    DraggableStateSnapshot
} from '@hello-pangea/dnd';
import StyleConfigSelector from "./StyleConfigSelector";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { IUpsertPortalStyleRequest } from "@/interfaces/Requests/Merchant";
import { useMutation } from "react-query";
import { useUpsertPortalStyleApi } from "@/lib/api/merchants";
import { AVAILABLE_DYNAMIC_COMPONENTS } from "@/Templates/constants/constants";
import { GenerateHelper } from "@/Templates/lib/GenerateHepler";

const { Panel } = Collapse;


// 定義 ref 的類型
export interface DynamicStyleConfigSelectorRef {
    getDynamicComponents: () => IDynamicComponent[];
}

interface DynamicStyleConfigSelectorProps {
    pageSetting: IMerchantPortalStyleSetting | null;
    isMobile: boolean;
    onConfigUpdate?: (components: IDynamicComponent[]) => void;
}

const DynamicStyleConfigSelector = forwardRef<DynamicStyleConfigSelectorRef, DynamicStyleConfigSelectorProps>(
    ({ pageSetting, isMobile, onConfigUpdate }, ref) => {
        const { merchantPortalStyleSettingsMutation, merchantId, messageApi } = useContext(GlobalContext);
        const { translate } = useContext(TranslationContext);
        const [dynamicComponents, setDynamicComponents] = useState<IDynamicComponent[]>([]);
        const [error, setError] = useState<string | null>(null);
        const styleConfigRefs = useRef<Array<{ getFormValues: () => Record<string, string>, setStyleOptions?: (options: any[]) => void } | null>>([]);
        const [componentConfigFields, setComponentConfigFields] = useState<Record<number, any[]>>({});
        const [styleOptions, setStyleOptions] = useState<any[]>([]);

        useEffect(() => {
            if (pageSetting?.dynamicComponents) {
                if (JSON.stringify(pageSetting.dynamicComponents) !== JSON.stringify(dynamicComponents)) {
                    setDynamicComponents(pageSetting.dynamicComponents);
                }
            } else {
                setDynamicComponents([]);
            }
        }, [pageSetting, pageSetting?.dynamicComponents]);

        useEffect(() => {
            // Initialize refs array when dynamicComponents changes
            styleConfigRefs.current = dynamicComponents.map(() => null);
        }, [dynamicComponents.length]);

        useEffect(() => {
            dynamicComponents.forEach(async (component, index) => {
                try {
                    const { fields, styleOptions } = await getDynamicComponentConfigFields(component);
                    setComponentConfigFields(prev => ({
                        ...prev,
                        [index]: fields
                    }));
                    setStyleOptions(styleOptions);
                } catch (error) {
                    console.error('Failed to load config fields:', error);
                    setComponentConfigFields(prev => ({
                        ...prev,
                        [index]: []
                    }));
                }
            });
        }, [dynamicComponents]);

        const handleDragEnd = (result: any) => {
            if (!result.destination) return;

            const { source, destination } = result;

            // 從可用元件拖到已選元件
            if (source.droppableId === 'available' && destination.droppableId === 'selected') {
                const componentType = AVAILABLE_DYNAMIC_COMPONENTS(translate)[source.index].value;
                const newComponent: IDynamicComponent = {
                    id: GenerateHelper.generateSnowFlakeBigInt(),
                    name: componentType,
                    style: 0,
                    values: {},
                };

                const newComponents = [...dynamicComponents];
                newComponents.splice(destination.index, 0, newComponent);
                setDynamicComponents(newComponents);
                updatePageSetting(newComponents);
            }
            // 已選元件重新排序
            else if (source.droppableId === 'selected' && destination.droppableId === 'selected') {
                const newComponents = Array.from(dynamicComponents);
                const [removed] = newComponents.splice(source.index, 1);
                newComponents.splice(destination.index, 0, removed);
                setDynamicComponents(newComponents);
                updatePageSetting(newComponents);
            }
        };

        const updatePageSetting = (components: IDynamicComponent[]) => {
            if (!pageSetting) return;

            setDynamicComponents(components);
            onConfigUpdate?.(components);
        };

        const handleDeleteComponent = (index: number) => {
            Modal.confirm({
                title: '確認刪除',
                content: '確定要刪除此元件嗎？',
                onOk: () => {
                    const newComponents = [...dynamicComponents];
                    newComponents.splice(index, 1);
                    setDynamicComponents(newComponents);
                    updatePageSetting(newComponents);
                },
            });
        };

        const handleStyleChange = (index: number, style: number) => {
            const newComponents = [...dynamicComponents];
            newComponents[index].style = style;
            setDynamicComponents(newComponents);
            updatePageSetting(newComponents);
        };

        const handleConfigChange = (index: number, key: string, value: any) => {
            const newComponents = [...dynamicComponents];
            newComponents[index].values = {
                ...newComponents[index].values,
                [key]: value,
            };
            setDynamicComponents(newComponents);
            updatePageSetting(newComponents);
        };

        const getDynamicComponentConfigFields = async (component: IDynamicComponent) => {
            try {
                // First try to get the current template to get config fields
                const componentFile = await import(`@/Templates/Shop/Components/${component.name}/Template_${component.style}.tsx`);

                // Get available template count by trying to import templates until we get an error
                let templateCount = 0;
                while (true) {
                    try {
                        await import(`@/Templates/Shop/Components/${component.name}/Template_${templateCount}.tsx`);
                        templateCount++;
                    } catch {
                        break;
                    }
                }

                // Generate style options array
                const styleOptions = Array.from({ length: templateCount }, (_, i) => ({
                    label: `Template ${i}`,
                    value: i
                }));

                return {
                    fields: componentFile.getConfigFields ? componentFile.getConfigFields() : [],
                    styleOptions
                };
            } catch (error) {
                console.error('加载动态组件模板失败:', error);
                return {
                    fields: [],
                    styleOptions: []
                };
            }
        };

        useImperativeHandle(ref, () => ({
            getDynamicComponents: () => {
                return dynamicComponents.map((component, index) => {
                    const formValues = styleConfigRefs.current[index]?.getFormValues() || {};
                    return {
                        ...component,
                        values: formValues
                    };
                });
            },
        }));

        return (
            <Row>
                <Col span={24}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Flex justify="space-between" align="center" className="p-4 bg-gray-50 w-full">
                            {/* 左側可用元件列表 */}
                            <div className={`${isMobile ? 'mb-4' : ''} flex-1 rounded-lg p-4 `}>
                                <h2 className="text-lg font-bold mb-4">{translate('Available Components')}</h2>
                                <Droppable
                                    droppableId="available"
                                    isDropDisabled={true} // 防止已選元件被拖回可用列表
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="space-y-2"
                                        >
                                            {AVAILABLE_DYNAMIC_COMPONENTS(translate).map((component, index) => (
                                                <Draggable
                                                    key={component.value}
                                                    draggableId={component.value}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`
                                                        bg-white p-3 rounded-md shadow-sm border 
                                                        ${snapshot.isDragging ? 'border-blue-500' : 'border-gray-200'} 
                                                        cursor-move hover:border-blue-400
                                                        transition-colors duration-200
                                                    `}
                                                        >
                                                            <span className="mr-2">{component.icon}</span>
                                                            {component.label}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>

                            {/* 右側已選元件列表 */}
                            <div className="flex-1 rounded-lg p-4">
                                <h2 className="text-lg font-bold mb-4">{translate('Selected Components')}</h2>
                                <Droppable droppableId="selected">
                                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={`
                                        space-y-4 min-h-[200px] 
                                        ${dynamicComponents.length === 0 ? 'border-2 border-dashed rounded-lg p-4' : ''}
                                        ${snapshot.isDraggingOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
                                        transition-colors duration-200
                                    `}
                                        >
                                            {dynamicComponents.length === 0 && (
                                                <div className="flex items-center justify-center h-[200px] text-gray-400">
                                                    {translate('Drag components here')}
                                                </div>
                                            )}
                                            {dynamicComponents.map((component, index) => (
                                                <Draggable
                                                    key={`${component.name}-${index}`}
                                                    draggableId={`${component.name}-${index}`}
                                                    index={index}
                                                >
                                                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`
                                                        ${snapshot.isDragging ? 'border-blue-500' : ''}
                                                    `}
                                                        >
                                                            <Card
                                                                className="bg-white rounded-lg shadow-sm"
                                                                title={
                                                                    <div {...provided.dragHandleProps} className="cursor-move">
                                                                        {AVAILABLE_DYNAMIC_COMPONENTS(translate).find(c => c.value === component.name)?.icon}
                                                                        {' '}
                                                                        {AVAILABLE_DYNAMIC_COMPONENTS(translate).find(c => c.value === component.name)?.label}
                                                                    </div>
                                                                }
                                                                extra={
                                                                    <Button
                                                                        type="text"
                                                                        danger
                                                                        icon={<DeleteOutlined />}
                                                                        onClick={() => handleDeleteComponent(index)}
                                                                    />
                                                                }
                                                            >
                                                                <Flex justify="center" align="center">
                                                                    <StyleConfigSelector
                                                                        ref={(el) => styleConfigRefs.current[index] = el}
                                                                        title={component.name}
                                                                        portalStyleSetting={pageSetting!}
                                                                        selectedStyle={component.style}
                                                                        styleConfig={component.values}
                                                                        onStyleChange={(value) => handleStyleChange(index, value)}
                                                                        onConfigChange={(key, value) => handleConfigChange(index, key, value)}
                                                                        configFields={componentConfigFields[index] || []}
                                                                        hideSaveButton={true}
                                                                        styleOptions={styleOptions}
                                                                    />
                                                                </Flex>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </Flex>

                        {error && <Alert type="error" message={error} className="mt-4" />}
                    </DragDropContext>
                </Col>
            </Row>
        );
    }
);

export default DynamicStyleConfigSelector;