import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApplyNewMerchant from "./Components/ApplyNewMerchant";
import MerchantInfos from "./Components/MerchantInfos";

const HomePage = () => {
  const { messageApi,
    isInitialized,
    hasMerchant,
    setHasMerchant,
    merchantId,
    setMerchantIdAggregate,
    isLogin,
    accessibleMerchants,
    getAccessibleMerchants,
    isBackOffice
  } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const navigate = useNavigate();

  useEffect(() => {
    getAccessibleMerchants.mutate();
  }, []);

  return (
    <>
      <MerchantInfos merchants={accessibleMerchants} />
    </>
  );
};

export default HomePage;