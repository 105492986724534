import { IUpdateBackOfficeMerchantThirdPartyPlatformServiceSettingRequest } from '@/interfaces/Requests/Requests';
import { IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel } from '@/interfaces/Responses/ThirdPartyLogistic';
import { useUpdateBackOfficeMerchantThirdPartyPlatformServiceSettingApi } from '@/lib/api/thirdPartyPlatformService';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { Button, Flex, Form, Input, Switch, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import ThirdPartyPlatformServiceProviderInputs from './Inputs/ThirdPartyPlatformServiceProviderInputs';
import InformationPopovers from './Popovers/InformationPopovers';
import ProviderGuidePopovers from './Popovers/ProviderGuidePopovers';

interface EditPlatformServiceSettingProps {
    record?: IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel;
    onRefresh: () => void;
    onClose: () => void;
}

const EditPlatformServiceSetting: React.FC<EditPlatformServiceSettingProps> = ({ record, onRefresh, onClose }) => {
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState<string>('');

    const updateSettingMutation = useMutation(async (request: IUpdateBackOfficeMerchantThirdPartyPlatformServiceSettingRequest) => await useUpdateBackOfficeMerchantThirdPartyPlatformServiceSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                onRefresh();
                onClose();
                messageApi.success(translate('Operation success'));
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        },
    });

    useEffect(() => {
        if (record && record.provider) {
            // 處理初始值
            const initialValues = Object.keys(record.values).reduce((acc, key) => {
                // 檢查是否是布爾類型的輸入參數
                const moduleKey = key.split('.')[0];
                const paramKey = key.split('.')[1];
                const moduleInputs = ThirdPartyPlatformServiceProviderInputs[record.provider][moduleKey]?.inputParameters || [];
                const isBooleanType = moduleInputs.some(input =>
                    Object.entries(input)[0][0] === paramKey &&
                    Object.entries(input)[0][1] === 'boolean'
                );

                if (isBooleanType) {
                    // 布爾類型的值，如果為空則設為 false
                    acc[key] = record.values[key] === "true" ? true : false;
                } else {
                    // 其他類型的值保持不變
                    acc[key] = record.values[key];
                }
                return acc;
            }, {} as Record<string, any>);

            form.setFieldsValue(initialValues);

            const providerInputs = ThirdPartyPlatformServiceProviderInputs[record.provider];
            if (providerInputs) {
                const firstModule = Object.keys(providerInputs)[0] as string;
                setActiveTab(firstModule);
            }
        }
    }, [record, form]);

    const handleSubmit = (values: any) => {
        if (record) {
            // 處理表單值
            Object.keys(values).forEach((key) => {
                if (values[key] === undefined || values[key] === null) {
                    // 如果值為 undefined 或 null，檢查是否為布爾類型
                    const moduleKey = key.split('.')[0];
                    const paramKey = key.split('.')[1];
                    const moduleInputs = ThirdPartyPlatformServiceProviderInputs[record.provider][moduleKey]?.inputParameters || [];
                    const isBooleanType = moduleInputs.some(input =>
                        Object.entries(input)[0][0] === paramKey &&
                        Object.entries(input)[0][1] === 'boolean'
                    );

                    values[key] = isBooleanType ? "false" : "";
                } else {
                    // 轉換為字符串
                    values[key] = values[key].toString();
                }
            });

            const updateRequest = {
                id: record.id,
                merchantId: record.merchantId,
                provider: record.provider,
                values: values,
            };

            updateSettingMutation.mutate(updateRequest);
        }
    };

    const renderFormFields = (moduleName: string) => {
        // 确保我们使用模块前缀来正确获取输入参数
        const inputs = ThirdPartyPlatformServiceProviderInputs[record?.provider || ''][moduleName]?.inputParameters || [];
        return inputs.map((input, index) => {
            if (input && Object.entries(input).length > 0) {
                const [key, type] = Object.entries(input)[0];
                // 生成带有模块前缀的字段名称
                const fullKey = `${moduleName}.${key}`;
                const label = (
                    <>
                        <InformationPopovers module={moduleName} parameter={`${key}`}></InformationPopovers>
                        {translate(key)}
                    </>
                )
                switch (type) {
                    case 'boolean':
                        return (
                            <Form.Item
                                key={index}
                                name={fullKey}
                                valuePropName="checked"
                                label={label}
                                initialValue={false}
                                rules={[{ required: true, message: `${key} is required` }]}
                            >
                                <Switch />
                            </Form.Item>
                        );
                    case 'string':
                        return (
                            <Form.Item
                                key={index}
                                name={fullKey}
                                label={label}
                                rules={[{ required: true, message: `${key} is required` }]}
                            >
                                <Input />
                            </Form.Item>
                        );
                    case 'number':
                        return (
                            <Form.Item
                                key={index}
                                name={fullKey}
                                label={label}
                                rules={[{ required: true, message: `${key} is required` }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        );
                    default:
                        return null;
                }
            }

            return null;
        });
    };
    const handleTabChange = (key: string) => {
        setActiveTab(key);
    };

    return (
        <div>
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    {record?.provider && Object.keys(ThirdPartyPlatformServiceProviderInputs[record.provider] || {}).map((moduleKey) => (
                        <Tabs.TabPane tab={translate(moduleKey)} key={moduleKey}>
                            {record?.provider && activeTab && (
                                <Flex justify='center'>
                                    <ProviderGuidePopovers provider={record.provider} module={activeTab} />
                                </Flex>)}
                            {renderFormFields(moduleKey)}
                        </Tabs.TabPane>
                    ))}
                </Tabs >
                <Form.Item>
                    <Flex justify='center'>
                        <Button type="primary" htmlType="submit" loading={updateSettingMutation.isLoading}>
                            {translate('Save')}
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditPlatformServiceSetting;
