import { MerchantWebsiteUpgradeLevel } from "@/enums/Enums";
import { ThirdPartyPaymentCreateResponseTypeEnum } from "@/enums/ThirdPartyPayment";
import { ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest, ICreatePaymentOrderForUpgradeRequest } from "@/interfaces/Requests/Requests";
import { ICancelSubscribeRequest } from "@/interfaces/Requests/ThirdPartyPayment";
import { IMerchant } from "@/interfaces/Responses/Responses";
import { useCreatePaymentOrderApi, useCreateTutorialUpgradeMerchantWebsiteLevelOrderApi, useCreateUpgradeMerchantWebsiteLevelOrderApi, useGetCanUpgradeMerchantWebsiteLevelsApi, useGetMerchantUpgradeLevelOrderHistoriseApi, useGetMerchantUpgradeMerchantWebsiteLevelOrderApi, useIsEnableToUnSubscribeApi } from "@/lib/api/merchants";
import { useCancelSubscribeApi } from "@/lib/api/thirdPartyPayments";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { UrlHelper } from "@/Templates/lib/UrlHelper";
import { Alert, Button, Card, Col, Divider, Flex, Modal, Row, Select, Typography, Table, Input, List } from 'antd';
import JSONBig from 'json-bigint';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { deviceType } from "react-device-detect";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const { Search } = Input;

export interface IMerchantUpgradeOrderProp {
    merchant: IMerchant | undefined;
}
export interface IMerchantUpgradeOrderRef {
    refetch: () => void;
    resetSelectedLevel: () => void;
}

const MerchantUpgradeOrder = React.forwardRef((props: IMerchantUpgradeOrderProp, ref: React.ForwardedRef<IMerchantUpgradeOrderRef | undefined>) => {
    const microShop_ECPay_Subscribe = BigInt(55688)
    const { Option } = Select;
    const { Title } = Typography;
    const uRLHelper = new UrlHelper();
    const navigation = useNavigate();
    const { messageApi } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const [selectedMerchant, setSelectedMerchant] = useState<IMerchant | undefined>(props.merchant);
    const { data: canUpgradeMerchantWebsiteLevelsResponse, mutate: refetchCanUpgradeMerchantWebsiteLevels, isLoading } = useMutation(
        async () => await useGetCanUpgradeMerchantWebsiteLevelsApi()
    );
    const merchantUpgradeLevelOrderMutation = useMutation(async () => await useGetMerchantUpgradeMerchantWebsiteLevelOrderApi());
    const merchantUpgradeLevelOrderHistoryMutation = useMutation(async () => await useGetMerchantUpgradeLevelOrderHistoriseApi());
    const [request, setRequest] = useState<ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest | undefined>(undefined);
    const { data: isEnableToUnSubscribeApiResponse, mutate: isEnableToUnSubscribeApiMutate } = useMutation(async () => useIsEnableToUnSubscribeApi());
    const createMerchantUpgradeLevelOrderMutation = useMutation(async (request: ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest) => {
        if (request.level === MerchantWebsiteUpgradeLevel.Tutorial) {
            var response = await useCreateTutorialUpgradeMerchantWebsiteLevelOrderApi(request);
            if (!response.isSuccess) {
                messageApi.error(translate(response.message || 'Operation failed'));
                return;
            }
            navigation(0);
            return response.result;
        }
        else {
            var requestForUpgrade: ICreatePaymentOrderForUpgradeRequest = {
                orderId: BigInt(0),
                settingId: microShop_ECPay_Subscribe,
                frontEndValues: {},
                level: request.level
            };
            var createResponse = await useCreateUpgradeMerchantWebsiteLevelOrderApi(requestForUpgrade);
            if (!createResponse.isSuccess) {
                messageApi.error(translate(createResponse.message || 'Operation failed'));
                return;
            }
            switch (createResponse.result?.thirdPartyPaymentCreateResponseTypeEnum) {
                case ThirdPartyPaymentCreateResponseTypeEnum.FormPost:
                    var dic = JSONBig.parse(createResponse.result?.data);
                    uRLHelper.pageFormPost(createResponse.result?.url, dic);
                    break;
                case ThirdPartyPaymentCreateResponseTypeEnum.Html:
                    break;
                case ThirdPartyPaymentCreateResponseTypeEnum.QRCode:
                    break;
                case ThirdPartyPaymentCreateResponseTypeEnum.Redirect:
                    break;
            }
        }
    });
    const [selectedLevel, setSelectedLevel] = useState<MerchantWebsiteUpgradeLevel | undefined>(undefined);

    const { mutate: cancelSubscribeMutate, isLoading: isCancelSubscribeApiLoadind } = useMutation(
        (request: ICancelSubscribeRequest) => useCancelSubscribeApi(request),
        {
            onSuccess: (data) => {
                if (data.isSuccess) {
                    messageApi.success(translate('Unsubscribe successful'));
                    refetchCanUpgradeMerchantWebsiteLevels();
                    isEnableToUnSubscribeApiMutate();
                } else {
                    messageApi.error(data.message || translate('Unsubscribe failed'));
                }
            },
            onError: (error) => {
                messageApi.error(translate('An error occurred while unsubscribing'));
            },
        }
    );

    const handleCardClick = (level: MerchantWebsiteUpgradeLevel) => {
        setRequest({ level: level });
        setSelectedLevel(level);
    };

    const unsubscribeContentZhTw = (
        <div>
            <p>您確定要取消訂閱嗎？請注意以下重要信息：</p>
            <ul>
                <li>取消訂閱後，您當前的訂閱級別及其所有權益將持續到本期合約到期日。</li>
                <li>在合約到期後，您的網站功能可能會受到限制。</li>
                <li>系統將在本期合約到期後停止自動扣款。</li>
                <li>您可以隨時重新訂閱以獲得完整功能。</li>
            </ul>
            <p>如果您有任何疑問，請聯繫客戶支持。</p>
        </div>
    );

    const unsubscribeContentEn = (
        <div>
            <p>Are you sure you want to unsubscribe? Please note the following important information:</p>
            <ul>
                <li>After unsubscribing, your current subscription level and all its benefits will continue until the end of the current contract period.</li>
                <li>After the contract expires, your website functionality may be limited.</li>
                <li>Automatic payments will be stopped after the current contract period ends.</li>
                <li>You can re-subscribe at any time to regain full functionality.</li>
            </ul>
            <p>If you have any questions, please contact customer support.</p>
        </div>
    );

    const handleUnsubscribe = () => {
        Modal.confirm({
            title: translate('Confirm Unsubscribe'),
            content: i18nLanguage === 'zh_TW' ? unsubscribeContentZhTw : unsubscribeContentEn,
            okText: i18nLanguage === 'zh_TW' ? '是的，取消訂閱' : 'Yes, Unsubscribe',
            cancelText: i18nLanguage === 'zh_TW' ? '否，保持我的訂閱' : 'No, Keep My Subscription',
            onOk: () => {
                if (props.merchant?.id) {
                    cancelSubscribeMutate({
                        merchantThirdPartyPaymentSettingId: microShop_ECPay_Subscribe,
                        frontEndValues: {},
                    });
                } else {
                    messageApi.error(translate('Merchant ID not found'));
                }
            },
        });
    };

    // 使用 useImperativeHandle 向父组件暴露函数
    useImperativeHandle(ref, () => ({
        refetch() {
            refetchCanUpgradeMerchantWebsiteLevels();
        },
        resetSelectedLevel() {
            setSelectedLevel(undefined);
        }
    }));

    useEffect(() => {
        isEnableToUnSubscribeApiMutate();
        setSelectedMerchant(props.merchant);
        refetchCanUpgradeMerchantWebsiteLevels();
        merchantUpgradeLevelOrderMutation.mutate();
        merchantUpgradeLevelOrderHistoryMutation.mutate();
    }, [props.merchant]);

    async function thirdPartyPaymentCreate(orderId: BigInt) {
        var response = await useCreatePaymentOrderApi({
            orderId: orderId,
            settingId: microShop_ECPay_Subscribe, // MicroShop 定期定額
            frontEndValues: {}
        });
        if (!response.isSuccess) {
            messageApi.error(translate(response.message || 'Operation failed'));
            return;
        }
        messageApi.success(translate('Operation success'));
        switch (response.result?.thirdPartyPaymentCreateResponseTypeEnum) {
            case ThirdPartyPaymentCreateResponseTypeEnum.FormPost:
                var dic = JSONBig.parse(response.result?.data);
                uRLHelper.pageFormPost(response.result?.url, dic);
                break;
            case ThirdPartyPaymentCreateResponseTypeEnum.Html:
                break;
            case ThirdPartyPaymentCreateResponseTypeEnum.QRCode:
                break;
            case ThirdPartyPaymentCreateResponseTypeEnum.Redirect:
                break;
        }
    }

    return (
        props.merchant &&
        <>
            <Row justify={"center"}>
                <Title level={5}>{props.merchant.name}</Title>
            </Row>

            {/* 當前訂閱信息 - 只在有數據且正在訂閱中時顯示 */}
            {
                merchantUpgradeLevelOrderMutation.data?.isSuccess &&
                merchantUpgradeLevelOrderMutation.data.result && (
                    <>
                        <Row justify="center">
                            <Title level={5}>{translate('Current Subscription')}</Title>
                        </Row>
                        <Col span={24} style={{ margin: '10px' }}>
                            <Title level={4}>{translate(`MerchantWebsiteUpgradeLevel.${merchantUpgradeLevelOrderMutation.data.result.level}`)}</Title>
                            <Title level={3}>$ {merchantUpgradeLevelOrderMutation.data.result.singlePeriodAmount} / {translate('Month')}</Title>
                            {
                                merchantUpgradeLevelOrderMutation.data.result.isSubscribing &&
                                <Flex justify="space-between">
                                    <Title level={5}>{translate('Subscribing')}</Title>
                                    <Button
                                        danger
                                        type="primary"
                                        onClick={handleUnsubscribe}
                                        loading={isCancelSubscribeApiLoadind}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        {translate('Unsubscribe')}
                                    </Button>
                                </Flex>
                            }
                            {
                                merchantUpgradeLevelOrderMutation.data.result.isCancel &&
                                <Title level={5} style={{ color: 'red' }}>{translate('Subscription canceled')}</Title>
                            }
                            {
                                merchantUpgradeLevelOrderMutation.data.result.isExpired &&
                                <Title level={5} style={{ color: 'red' }}>{translate('Subscription expired')}</Title>
                            }
                        </Col>

                        <Divider />
                    </>
                )
            }

            {/* 升級方案部分 */}
            <Row justify={"center"}>
                <Col span={24}>
                    <Title level={5} style={{ textAlign: 'center' }}>
                        {translate('Upgrade Plans')}
                    </Title>
                    {i18nLanguage === 'zh_TW' ? (
                        <Alert
                            description="我方平台會每月扣款，並且自動訂閱最長1年(可隨時解除)。"
                            type="warning"
                            showIcon
                        />
                    ) : (
                        <Alert
                            description="Our platform will charge monthly and automatically subscribe for up to 1 year (can be canceled at any time)."
                            type="warning"
                            showIcon
                        />
                    )}
                </Col>
            </Row>

            {/* 升級方案卡片和按鈕 */}
            {isLoading ? (
                <div style={{ margin: '16px' }}>
                    <LoadingComponent />
                </div>
            ) : (
                <>
                    <Row justify={"center"} style={{ margin: '10px' }}>
                        {canUpgradeMerchantWebsiteLevelsResponse?.isSuccess &&
                            canUpgradeMerchantWebsiteLevelsResponse.result &&
                            canUpgradeMerchantWebsiteLevelsResponse.result
                                .filter(r => r.level !== MerchantWebsiteUpgradeLevel.None)
                                .map((vm) => (
                                    <Col span={deviceType === 'desktop' ? 12 : 24} key={uuidv4()}>
                                        <Card
                                            key={vm.level}
                                            style={{
                                                width: '90%',
                                                margin: '10px',
                                                borderColor: selectedLevel === vm.level ? 'blue' : undefined,
                                                backgroundColor: selectedLevel === vm.level ? 'lightblue' : undefined
                                            }}
                                            onClick={() => handleCardClick(vm.level)}
                                            hoverable
                                        >
                                            <Title level={5}>{translate(`MerchantWebsiteUpgradeLevel.${vm.level}`)}</Title>
                                            {
                                                vm.level === MerchantWebsiteUpgradeLevel.Tutorial &&
                                                (<Title level={5}>{translate('3-Day Free Trial')}</Title>)
                                            }
                                            {
                                                vm.level !== MerchantWebsiteUpgradeLevel.Tutorial &&
                                                <Title level={4}>{vm.price} {vm.currencyCode} / {translate('Month')}</Title>
                                            }
                                        </Card>
                                    </Col>
                                ))}
                    </Row>
                    <Row justify={"center"}>
                        <Button
                            loading={createMerchantUpgradeLevelOrderMutation.isLoading}
                            disabled={selectedLevel === undefined}
                            onClick={() => {
                                createMerchantUpgradeLevelOrderMutation.mutate(request!);
                            }}>
                            {isEnableToUnSubscribeApiResponse?.isSuccess && isEnableToUnSubscribeApiResponse.result ? translate('Upgrade') : translate('Subscribe')}
                        </Button>
                    </Row>

                    {/* 取消訂閱按鈕部分 */}
                    {isEnableToUnSubscribeApiResponse?.isSuccess && isEnableToUnSubscribeApiResponse.result && (
                        <>
                            <Divider />
                            <Row justify="center" style={{ marginTop: '10px' }}>
                                <Button
                                    danger
                                    onClick={handleUnsubscribe}
                                    loading={isCancelSubscribeApiLoadind}>
                                    {translate('Unsubscribe')}
                                </Button>
                            </Row>
                        </>
                    )}
                </>
            )}

            {/* 帳單歷史記錄 - 只在有資料時顯示 */}
            {merchantUpgradeLevelOrderHistoryMutation.data?.isSuccess &&
                merchantUpgradeLevelOrderHistoryMutation.data.result &&
                merchantUpgradeLevelOrderHistoryMutation.data.result.length > 0 && (
                    <>
                        <Divider />
                        <Row justify="center">
                            <Col span={20}>
                                <Title level={5} style={{ textAlign: 'center' }}>{translate('Billing History')}</Title>
                                <List
                                    dataSource={merchantUpgradeLevelOrderHistoryMutation.data.result}
                                    renderItem={item => (
                                        <List.Item>
                                            <Row style={{ width: '100%' }} justify="space-between">
                                                <Col span={6}>
                                                    {new Date(item.createTime).toLocaleDateString()}
                                                </Col>
                                                <Col span={6}>
                                                    {translate(`MerchantWebsiteUpgradeLevel.${item.level}`)}
                                                </Col>
                                                <Col span={6}>
                                                    $ {item.singlePeriodAmount}
                                                </Col>
                                                <Col span={6}>
                                                    {item.isPaid ? translate('Active') : translate('Expired')}
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </>
                )}
        </>
    );
});

export default MerchantUpgradeOrder;