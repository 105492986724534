import { IAlterMerchantThirdPartyInvoiceSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel } from "@/interfaces/Responses/Responses";
import { useAlterMerchantThirdPartyInvoiceSettingApi, useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { PlusOutlined } from '@ant-design/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Button, Flex, Modal, Space, Switch, Tag } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import AddInvoice, { IAddInvoiceRef } from "./AddInvoice";
import EditInvoice, { IEditInvoiceRef } from "./EditInvoice";

export interface IInvoiceListProps {
}

export interface IInvoiceSettingListRef {
}

const Row = (props: {
    row: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel,
    onToggleDisable: (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, isDisable: boolean) => void,
    isLoading: boolean,
    onRefresh: () => void
}) => {
    const [open, setOpen] = useState(false);
    const { translate } = useContext(TranslationContext);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center" style={{ textAlign: 'center' }}>
                    <Space>
                        <span>{props.row.providerName}</span>
                        <Tag color="blue">
                            {props.row.supportedCountry.split(',').map(country => translate(`Country.${country}`)).join(', ')}
                        </Tag>
                    </Space>
                </TableCell>
                <TableCell align="center" style={{ textAlign: 'center' }}>
                    <Switch
                        loading={props.isLoading}
                        checked={!props.row.isDisable}
                        onChange={(checked) => props.onToggleDisable(props.row, !checked)}
                        checkedChildren={translate('Enable')}
                        unCheckedChildren={translate('Disable')}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="p-4">
                            <Space direction="vertical" className="w-full">
                                <Flex justify="center" align="center">
                                    <EditInvoice
                                        record={props.row}
                                    />
                                </Flex>
                            </Space>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const InvoiceSettingList = React.forwardRef((props: IInvoiceListProps, ref: React.ForwardedRef<IInvoiceSettingListRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const abortController = React.useRef(new AbortController());
    const addRef = useRef<IAddInvoiceRef>();
    const editRef = useRef<IEditInvoiceRef>();
    const [merchantThirdPartySettings, setMerchantThirdPartySettings] = useState<IBackOfficeMerchantThirdPartyInvoiceSettingViewModel[]>([]);

    const { mutate, isLoading } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi(abortController.current.signal), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantThirdPartySettings(response.result || []);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: alterMutate, isLoading: alterMutateIsLoading } = useMutation(async (request: IAlterMerchantThirdPartyInvoiceSettingRequest) => await useAlterMerchantThirdPartyInvoiceSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                refresh();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const [openAddSetting, setOpenAddSetting] = useState(false);

    const handleToggleDisable = (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, isDisable: boolean) => {
        // 檢查是否要啟用
        if (!isDisable) {
            // 取得當前記錄的國家列表
            const currentCountries = record.supportedCountry.split(',');

            // 檢查是否有其他已啟用且有相同國家的設定
            const conflictSettings = merchantThirdPartySettings.filter(setting =>
                !setting.isDisable && // 已啟用
                setting.id !== record.id && // 不是當前記錄
                setting.supportedCountry.split(',').some(country =>
                    currentCountries.includes(country)
                )
            );

            if (conflictSettings.length > 0) {
                Modal.confirm({
                    title: translate('Warning'),
                    content: `${translate('Only one method can be enabled in the same country. Are you sure you want to switch')}?`,
                    okText: translate('Confirm'),
                    cancelText: translate('Cancel'),
                    onOk: () => {
                        alterMutate({
                            settingId: record.thirdPartySettingId,
                            setting: record.merchantSettings,
                            isDisabled: isDisable
                        });
                    }
                });
                return;
            }
        }

        // 如果是停用或沒有衝突，直接執行
        alterMutate({
            settingId: record.thirdPartySettingId,
            setting: record.merchantSettings,
            isDisabled: isDisable
        });
    };

    const refresh = () => {
        mutate();
    };

    useEffect(() => {
        abortController.current = new AbortController();
        refresh();
        console.log('refresh');
        return () => {
            abortController.current.abort();
        }
    }, []);

    return (
        <div style={{ margin: '16px' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center">{translate('Provider')}</TableCell>
                            <TableCell align="center">{translate('Status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {merchantThirdPartySettings.map((row) => (
                            <Row
                                key={row.id.toString()}
                                row={row}
                                onToggleDisable={handleToggleDisable}
                                isLoading={isLoading || alterMutateIsLoading}
                                onRefresh={refresh}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                type="dashed"
                onClick={() => setOpenAddSetting(true)}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: '16px' }}
            >
                {translate('Add new setting')}
            </Button>

            <Modal
                open={openAddSetting}
                afterOpenChange={(open) => {
                    if (open) addRef.current?.refresh();
                }}
                onCancel={() => setOpenAddSetting(false)}
                footer={null}
            >
                <AddInvoice
                    ref={addRef}
                    ownRecords={merchantThirdPartySettings}
                    onRefresh={() => refresh()}
                    onClose={() => setOpenAddSetting(false)}
                />
            </Modal>
        </div>
    );
});

export default InvoiceSettingList;
