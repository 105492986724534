import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { DeviceType, MerchantPortalStyleSettingType } from '@/Templates/enums/templateEnums';
import { IDynamicComponent, IMerchantPortalStyleSetting, IOperationResult } from '@/Templates/interfaces/templatesInterfaces';
import { LaptopOutlined, MobileOutlined, PlusOutlined, TabletOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Empty, Flex, Input, Row, Select, Modal, Card, Form } from 'antd';
import { useContext, useEffect, useState, useRef, useMemo, Suspense } from 'react';
import DynamicStyleConfigSelector from './DynamicStyleConfigSelector';
import PreviewContainer from './PreviewContainer';
import { BasePreviewProps } from './types';
import { useDeletePortalStyleApi, useUpsertPortalStyleApi } from '@/lib/api/merchants';
import { useMutation } from 'react-query';
import { useForm, useWatch } from 'antd/es/form/Form';
import { DynamicStyleConfigSelectorRef } from './DynamicStyleConfigSelector';
import { useGetMerchantPortalStyleSettingsApi } from '@/lib/api/portals';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import DynamicPageTemplate from '@/Templates/Shop/Pages/DynamicPage/DynamicPageTemplate';


const DynamicPagePreview: React.FC<BasePreviewProps> = ({
    containerwidth,
    previewdevicetype,
    windowdevicetype,
    previewContainerRef,
    handlePreviewDeviceChange,
    getPreviewWidth,
    translate,
    showSeleceTestDataDrawer
}) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const [pageSettings, setPageSettings] = useState<IMerchantPortalStyleSetting[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedPageSetting, setSelectedPageSetting] = useState<IMerchantPortalStyleSetting | null>(() => {
        const pageId = searchParams.get('dynamicPageId');
        if (pageId && pageSettings.length) {
            return pageSettings.find(page => page.id.toString() === pageId) || null;
        }
        return null;
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageName, setPageName] = useState<string>(translate('New Page'));
    const [pagePath, setPagePath] = useState<string>('');
    const [newPageName, setNewPageName] = useState<string>(translate('New Page'));
    const [newPagePath, setNewPagePath] = useState<string>('');
    const configSelectorRef = useRef<DynamicStyleConfigSelectorRef>(null);
    const merchantPortalStyleSettingsMutation = useMutation("merchantPortalStyleSettingsMutation", async (merchantId: BigInt) => {
        return await useGetMerchantPortalStyleSettingsApi(merchantId);
    });
    const DynamicPageTemplateComponent = useMemo(() => {
        if (!selectedPageSetting?.dynamicComponents) {
            return <></>;
        }

        return <DynamicPageTemplate components={selectedPageSetting.dynamicComponents} />;
    }, [selectedPageSetting?.dynamicComponents]);

    useEffect(() => {
        merchantPortalStyleSettingsMutation.mutate(merchantId!);
    }, []);

    useEffect(() => {
        if (merchantPortalStyleSettingsMutation.data?.result && merchantPortalStyleSettingsMutation.data?.result?.length > 0) {
            setPageSettings(merchantPortalStyleSettingsMutation.data?.result?.filter((page) => page.type === MerchantPortalStyleSettingType.DynamicPages));
        }
        else {
            setPageSettings([]);
            setSelectedPageSetting(null);
        }
    }, [merchantPortalStyleSettingsMutation.data?.result]);

    useEffect(() => {
        if (selectedPageSetting) {
            setPageName(selectedPageSetting.name);
            setPagePath(selectedPageSetting.pagePath);
        }
    }, [selectedPageSetting]);

    const handleConfigUpdate = (updatedComponents: IDynamicComponent[]) => {
        if (!selectedPageSetting) return;

        setSelectedPageSetting({
            ...selectedPageSetting,
            dynamicComponents: updatedComponents
        });
    };

    const handleDeleteDynamicPage = useMutation("handleDeleteDynamicPage", async () => {
        return new Promise<IOperationResult | null>((resolve, reject) => {
            Modal.confirm({
                title: translate('Are you sure you want to delete this page?'),
                onOk: async () => {
                    try {
                        if (!selectedPageSetting) {
                            reject(new Error('No page selected'));
                            return;
                        }
                        const response = await useDeletePortalStyleApi({
                            id: selectedPageSetting.id,
                        });
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                },
                onCancel: () => {
                    reject(new Error('Operation cancelled'));
                }
            });
        });
    }, {
        onSuccess: (response: IOperationResult | null) => {
            if (response?.isSuccess) {
                messageApi.success(translate('Operation success'));
                merchantPortalStyleSettingsMutation.mutate(merchantId!);
            }
        },
        onError: (error: any) => {
            if (error.message !== 'Operation cancelled') {
                messageApi.error(translate('Operation failed'));
            }
        }
    });

    const configSelectorComponent = useMemo(() => (
        <DynamicStyleConfigSelector
            ref={configSelectorRef}
            pageSetting={selectedPageSetting}
            isMobile={windowdevicetype !== DeviceType.Desktop}
            onConfigUpdate={handleConfigUpdate}
        />
    ), [selectedPageSetting, windowdevicetype]);

    const handleAddDynamicPage = useMutation("handleAddDynamicPage", async () => {
        await useUpsertPortalStyleApi({
            id: BigInt(0),
            type: MerchantPortalStyleSettingType.DynamicPages,
            name: newPageName,
            style: 0,
            styleSetting: {},
            pagePath: newPagePath,
            dynamicComponents: [],
        });
    }, {
        onSuccess: () => {
            messageApi.success(translate('Operation success'));
            merchantPortalStyleSettingsMutation.mutate(merchantId!, {
                onSuccess: (response) => {
                    if (response.isSuccess) {
                        setSelectedPageSetting(response.result?.at(-1) ?? null);
                    }
                }
            });
            setIsModalOpen(false);
        }
    });

    const handleUpdateDynamicPage = useMutation("handleUpdateDynamicPage", async () => {
        if (!selectedPageSetting) return null;
        const response = await useUpsertPortalStyleApi({
            ...selectedPageSetting,
            name: pageName,
            pagePath: pagePath,
            dynamicComponents: configSelectorRef.current?.getDynamicComponents() ?? [],
        });
        return response;
    }, {
        onSuccess: (response: IOperationResult | null) => {
            if (response?.isSuccess) {
                messageApi.success(translate('Operation success'));
                merchantPortalStyleSettingsMutation.mutate(merchantId!, {
                    onSuccess: (response) => {
                        if (response.isSuccess) {
                            console.log('response.result', response.result);
                            console.log('selectedPageSetting', selectedPageSetting?.id.toString());
                            console.log('response.result?.find((setting) => setting.id.toString() == selectedPageSetting?.id.toString())', response.result?.find((setting) => setting.id.toString() == selectedPageSetting?.id.toString()));
                            setSelectedPageSetting(response.result?.find((setting) => setting.id.toString() == selectedPageSetting?.id.toString()) ?? null);
                        }
                    }
                });
            }
        }
    });

    const handlePageSelect = (value: string) => {
        setSelectedPageSetting(pageSettings.find((styleSetting) =>
            styleSetting.id.toString() === value) || null);
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('dynamicPageId', value);
            return newParams;
        });
    };

    const renderDeviceTypeButtons = () => (
        <Flex justify="center" align="center">
            <Button
                icon={<MobileOutlined />}
                onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                type={previewdevicetype === DeviceType.Mobile ? 'primary' : 'default'}
            >
                {translate('Mobile')}
            </Button>
            <Button
                icon={<TabletOutlined />}
                onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                type={previewdevicetype === DeviceType.LapTop ? 'primary' : 'default'}
            >
                {translate('Laptop')}
            </Button>
            <Button
                icon={<LaptopOutlined />}
                onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                type={previewdevicetype === DeviceType.Desktop ? 'primary' : 'default'}
            >
                {translate('Desktop')}
            </Button>
        </Flex>
    );

    const renderPreviewContainer = () => (
        <Flex justify="center" align="center">
            <PreviewContainer
                ref={previewContainerRef}
                containerwidth={containerwidth}
                previewwidth={getPreviewWidth()}
                previewdevicetype={previewdevicetype}
                windowdevicetype={windowdevicetype}
            >
                <div style={{
                    width: '100%',
                    minHeight: '100%',
                    position: 'relative',
                    transform: 'none',
                    left: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch'
                }}>
                    {DynamicPageTemplateComponent}
                </div>
            </PreviewContainer>
        </Flex>
    );

    const renderConfiguratorForDesktop = () => (
        <Col span={12}>
            <Flex justify="center" align="center" style={{ padding: '10px' }} gap="20px">
                {configSelectorComponent}
            </Flex>
        </Col>
    );

    const renderConfiguratorForMobile = () => (
        <>
            <Button
                type="primary"
                onClick={() => setIsDrawerOpen(true)}
                style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}
            >
                {translate('Configure Components')}
            </Button>
            <Drawer
                title={translate('Component Settings')}
                placement="right"
                width="100%"
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
                style={{ position: 'absolute' }}
                contentWrapperStyle={{ position: 'absolute' }}
                mask={false}
                bodyStyle={{
                    overflow: 'visible',
                    height: '100vh',
                    padding: 0
                }}
            >
                <div className="h-full overflow-auto p-4">
                    {configSelectorComponent}
                </div>
            </Drawer>
        </>
    );

    const renderSelectedPageContent = () => (
        <>
            <Col span={24} style={{ marginBottom: 16 }}>
                {renderDeviceTypeButtons()}
            </Col>
            <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24} style={{ marginBottom: windowdevicetype === DeviceType.Desktop ? 0 : 16 }}>
                {renderPreviewContainer()}
            </Col>
            {windowdevicetype === DeviceType.Desktop ? renderConfiguratorForDesktop() : renderConfiguratorForMobile()}
            <Col span={24} style={{ marginTop: 16 }}>
                <Flex justify="center" align="center">
                    <Button
                        type="primary"
                        onClick={() => handleUpdateDynamicPage.mutate()}
                        loading={handleUpdateDynamicPage.isLoading}
                    >
                        {translate('Save')}
                    </Button>
                </Flex>
            </Col>
        </>
    );

    return (
        <div>
            <Row style={{ marginBottom: 16 }}>
                <Col span={24} style={{ marginBottom: 16 }}>
                    <Flex justify="center" align="center">
                        <Card>
                            <Form layout="vertical">
                                <Form.Item label={translate('Pages')}>
                                    <Select
                                        style={{ width: 200 }}
                                        value={selectedPageSetting?.id.toString()}
                                        onChange={handlePageSelect}
                                    >
                                        {merchantPortalStyleSettingsMutation.data?.result?.filter((page) => page.type === MerchantPortalStyleSettingType.DynamicPages).map((styleSetting) => (
                                            <Select.Option
                                                key={styleSetting.id.toString()}
                                                value={styleSetting.id.toString()}
                                            >
                                                {translate(styleSetting.name)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <Button
                                        type="primary"
                                        onClick={() => setIsModalOpen(true)}
                                        loading={handleAddDynamicPage.isLoading}
                                    >
                                        {translate('Add')}
                                    </Button>
                                    {
                                        selectedPageSetting &&
                                        <Button
                                            type="primary"
                                            onClick={() => handleDeleteDynamicPage.mutate()}
                                            loading={handleDeleteDynamicPage.isLoading}
                                        >
                                            {translate('Delete')}
                                        </Button>
                                    }
                                </Form.Item>
                                {
                                    selectedPageSetting &&
                                    <>
                                        <Form.Item label={translate('Name')}>
                                            <Input
                                                value={pageName}
                                                onChange={(e) => setPageName(e.target.value)}
                                                placeholder={translate('Enter page name')}
                                            />
                                        </Form.Item>
                                        <Form.Item label={translate('Path')}>
                                            <Input
                                                value={pagePath}
                                                onChange={(e) => setPagePath(e.target.value)}
                                                placeholder={translate('Enter page path')}
                                            />
                                        </Form.Item>
                                    </>
                                }
                            </Form>
                        </Card>
                    </Flex>
                </Col>
                {!selectedPageSetting && (
                    <Col span={24} style={{ marginBottom: 16 }}>
                        <Empty description={translate('Please select a page')} />
                    </Col>
                )}
                {selectedPageSetting && renderSelectedPageContent()}
            </Row>

            <Modal
                title={translate('Add New Page')}
                open={isModalOpen}
                onOk={() => handleAddDynamicPage.mutate()}
                onCancel={() => setIsModalOpen(false)}
                confirmLoading={handleAddDynamicPage.isLoading}
            >
                <Form layout="vertical">
                    <Form.Item
                        label={translate('Page Name')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Input
                            value={newPageName}
                            onChange={(e) => setNewPageName(e.target.value)}
                            placeholder={translate('Page Name')} />
                    </Form.Item>
                    <Form.Item
                        name="pagePath"
                        label={translate('Page Path')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Input
                            value={newPagePath}
                            onChange={(e) => setNewPagePath(e.target.value)}
                            placeholder={translate('Page Path')} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default DynamicPagePreview;