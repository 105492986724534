import { Button, Typography, Space, Tag, Row, Col, Modal, Form, Input, Select, InputNumber, Switch, Descriptions } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { useCreateMemberConsumptionLevelApi, useAssignMemberConsumptionLevelApi, useGetMemberConsumptionLevelsApi, useDeleteMemberConsumptionLevelApi, useUpdateMemberConsumptionLevelApi } from "@/lib/api/merchants";
import { useMutation } from "react-query";
import { IAssignMemberConsumptionLevelRequest, ICreateMemberConsumptionLevelRequest, IMemberLevelConsumptionViewModel } from "@/interfaces/Responses/Merchant";
import { PlusOutlined, MinusOutlined, ArrowDownOutlined, DeleteOutlined, EditOutlined, RetweetOutlined } from '@ant-design/icons';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import CreateMemberConsumptionLevelCard from './CreateMemberConsumptionLevelCard';
import { IUpdateMemberConsumptionLevelRequest } from "@/interfaces/Requests/Merchant";

const { Text } = Typography;

// 添加等級卡片的樣式配置
const levelStyles = {
    0: { // 預設等級
        background: 'linear-gradient(135deg, #e0e0e0 0%, #b8b8b8 100%)',
        textColor: '#333333'
    },
    1: { // 銀卡
        background: 'linear-gradient(135deg, #C0C0C0 0%, #808080 100%)',
        textColor: '#ffffff'
    },
    2: { // 金卡
        background: 'linear-gradient(135deg, #FFD700 0%, #DAA520 100%)',
        textColor: '#ffffff'
    },
    3: { // 白金卡
        background: 'linear-gradient(135deg, #E5E4E2 0%, #98989C 100%)',
        textColor: '#ffffff'
    },
    default: { // 其他等級
        background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
        textColor: '#ffffff'
    }
};

const getLevelStyle = (index: number) => {
    return levelStyles[index as keyof typeof levelStyles] || levelStyles.default;
};

// 定義每個等級的折扣
const getLevelDiscount = (index: number) => {
    const discounts = {
        0: 0.95, // 預設等級 95折
        1: 0.9,  // 銀卡 9折
        2: 0.85, // 金卡 85折
        3: 0.8,  // 白金卡 8折
    };

    return discounts[index as keyof typeof discounts] || 0.95;
};

// 定義每個等級的優惠內容
const getLevelBenefits = (index: number) => {
    const benefits = {
        discount: {
            0: 0.95, // 預設等級 95折
            1: 0.9,  // 銀卡 9折
            2: 0.85, // 金卡 85折
            3: 0.8,  // 白金卡 8折
        },
        freeShipping: {
            0: 1000,   // 預設等級 滿1000免運
            1: 800,    // 銀卡 滿800免運
            2: 500,    // 金卡 滿500免運
            3: 0,      // 白金卡 全面免運
        }
    };

    return {
        discount: benefits.discount[index as keyof typeof benefits.discount] || 0.95,
        freeShipping: benefits.freeShipping[index as keyof typeof benefits.freeShipping] || 1000
    };
};

// 顯示實際折扣的輔助函數
const getActualDiscount = (i18nLanguage: string, percentOff: number) => {
    if (i18nLanguage === 'zh_TW') {
        if (percentOff === 0) return '(未打折)';
        return `(${(100 - percentOff) / 10}折)`;
    }
    return '';
};

const MemberConsumptionLevelTab: React.FC = () => {
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [editingLevel, setEditingLevel] = useState<IMemberLevelConsumptionViewModel | null>(null);
    const [form] = Form.useForm();
    const [flippedCards, setFlippedCards] = useState<Set<number>>(new Set());
    const [newLevelThreshold, setNewLevelThreshold] = useState<number | null>(null);

    const GetMemberLevelsApiMutation = useMutation(async () => await useGetMemberConsumptionLevelsApi());
    const CreateMemberConsumptionLevelApiMutation = useMutation(
        async (params: ICreateMemberConsumptionLevelRequest) => await useCreateMemberConsumptionLevelApi(params),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    setShowCreateForm(false);
                    GetMemberLevelsApiMutation.mutate();
                    form.resetFields();
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const DeleteMemberConsumptionLevelApiMutation = useMutation({
        mutationFn: (levelId: number) => useDeleteMemberConsumptionLevelApi(levelId),
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                GetMemberLevelsApiMutation.mutate();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        },
        onError: (error) => {
            messageApi.error(translate('Operation failed'));
        }
    });

    const UpdateMemberLevelApiMutation = useMutation({
        mutationFn: (request: IUpdateMemberConsumptionLevelRequest) => useUpdateMemberConsumptionLevelApi(request),
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                setEditingLevel(null);
                GetMemberLevelsApiMutation.mutate();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        },
        onError: (error) => {
            messageApi.error(translate('Operation failed'));
        }
    });

    useEffect(() => {
        GetMemberLevelsApiMutation.mutate();
    }, []);

    // 檢查折扣是否符合規則
    const validateDiscount = (currentPercentOff: number) => {
        const levels = GetMemberLevelsApiMutation.data?.result || [];

        // 找到前一個和後一個等級
        const currentIndex = levels.findIndex(level => level.id === editingLevel?.id);
        const previousLevel = levels[currentIndex - 1];
        const nextLevel = levels[currentIndex + 1];

        // 新增時的檢查
        if (!editingLevel) {
            const lastLevel = levels[levels.length - 1];
            if (lastLevel && currentPercentOff < lastLevel.percentOff) {
                return Promise.reject(
                    translate('優惠不得低於前一等級')
                );
            }
            return Promise.resolve();
        }

        // 編輯時的檢查
        if (previousLevel && currentPercentOff < previousLevel.percentOff) {
            return Promise.reject(
                translate('優惠不得低於前一等級')
            );
        }

        if (nextLevel && currentPercentOff > nextLevel.percentOff) {
            return Promise.reject(
                translate('優惠不得高於後一等級')
            );
        }

        return Promise.resolve();
    };

    // 檢查累積消費是否符合規則
    const validateThreshold = (currentThreshold: number) => {
        const levels = GetMemberLevelsApiMutation.data?.result || [];

        // 找到前一個和後一個等級
        const currentIndex = levels.findIndex(level => level.id === editingLevel?.id);
        const previousLevel = levels[currentIndex - 1];
        const nextLevel = levels[currentIndex + 1];

        // 新增時的檢查
        if (!editingLevel) {
            const lastLevel = levels[levels.length - 1];
            if (lastLevel && currentThreshold <= lastLevel.threshold) {
                return Promise.reject(
                    translate('累積消費不得低於前一等級')
                );
            }
            return Promise.resolve();
        }

        // 編輯時的檢查
        if (previousLevel && currentThreshold <= previousLevel.threshold) {
            return Promise.reject(
                translate('累積消費不得低於前一等級')
            );
        }

        if (nextLevel && currentThreshold >= nextLevel.threshold) {
            return Promise.reject(
                translate('累積消費不得高於後一等級')
            );
        }

        return Promise.resolve();
    };

    // 修改提交處理函數
    const handleSubmit = async (values: ICreateMemberConsumptionLevelRequest) => {
        await CreateMemberConsumptionLevelApiMutation.mutate(values);
    };

    const handleDelete = (levelId: number) => {
        Modal.confirm({
            title: translate('Confirm Delete'),
            content: translate('Are you sure you want to delete this level?'),
            okText: translate('Confirm'),
            cancelText: translate('Cancel'),
            onOk: () => {
                DeleteMemberConsumptionLevelApiMutation.mutate(levelId);
            }
        });
    };

    const handleEdit = (level: IMemberLevelConsumptionViewModel) => {
        setShowCreateForm(false);
        setEditingLevel(level);
        form.setFieldsValue({
            name: level.name,
            threshold: level.threshold,
            percentOff: level.percentOff
        });
    };

    const handleCancelEdit = () => {
        if (editingLevel) {
            form.setFieldsValue({
                name: editingLevel.name,
                threshold: editingLevel.threshold,
                percentOff: editingLevel.percentOff
            });
        }
        setEditingLevel(null);
    };

    // 修改保存處理函數
    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            await UpdateMemberLevelApiMutation.mutate({
                id: editingLevel?.id || 0,
                name: values.name,
                threshold: values.threshold,
                percentOff: values.percentOff
            });
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const toggleCard = (levelId: number) => {
        const newFlippedCards = new Set(flippedCards);
        if (newFlippedCards.has(levelId)) {
            newFlippedCards.delete(levelId);
        } else {
            newFlippedCards.add(levelId);
        }
        setFlippedCards(newFlippedCards);
    };

    // 在表單中使用自定義驗證規則
    const handleFormValuesChange = (changedValues: any) => {
        if ('threshold' in changedValues) {
            setNewLevelThreshold(changedValues.threshold);
        }

        // 當折扣值改變時進行驗證
        if ('percentOff' in changedValues) {
            const values = form.getFieldsValue();
            validateDiscount(values.percentOff);
        }
    };

    // 修改點擊新增按鈕的處理函數
    const handleAddNewLevel = () => {
        setEditingLevel(null);
        setShowCreateForm(true);

        // 獲取最後一個等級的設定
        const lastLevel = GetMemberLevelsApiMutation.data?.result?.[
            (GetMemberLevelsApiMutation.data?.result?.length || 0) - 1
        ];

        // 如果存在上一個等級，則設置預設值
        if (lastLevel) {
            form.setFieldsValue({
                threshold: lastLevel.threshold,
                percentOff: lastLevel.percentOff,
                name: '' // 名稱需要用戶自行輸入
            });
            setNewLevelThreshold(lastLevel.threshold);
        } else {
            form.resetFields();
        }
    };

    // 添加 CSS 樣式到你的 styles 文件中
    const cardStyles = `
        .card-container {
            width: 400px;
            margin: 0 auto;
            position: relative;
        }

        .card {
            width: 100%;
            min-height: 252px;
            border-radius: 0.5rem;
            padding: 1.5rem;
            position: relative;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.3s ease;
        }
        
        .card:hover {
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
        }

        .edit-form {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .edit-form .ant-form-item {
            margin-bottom: 0;
        }

        .edit-form .ant-form-item-label > label {
            font-size: 14px;
            height: 24px;
        }

        .edit-form .ant-input,
        .edit-form .ant-input-number {
            height: 36px;
        }

        .edit-form .buttons {
            margin-top: 8px;
            display: flex;
            justify-content: flex-end;
            gap: 8px;
        }

        .level-arrow {
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
        }

        .add-level-button {
            width: 100%;
            min-height: 252px;
            border: 2px dashed #d9d9d9;
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s;
        }

        .add-level-button:hover {
            border-color: #1890ff;
        }

        .add-level-button .add-text {
            color: #8c8c8c;
            font-size: 16px;
        }

        .add-level-button:hover .add-text {
            color: #1890ff;
        }
    `;

    return (
        <div className="p-6">
            <style>{cardStyles}</style>

            {/* 現有的會員等級卡片 */}
            <div className="space-y-4">
                {GetMemberLevelsApiMutation.data?.result?.map((level, index) => (
                    <React.Fragment key={level.id}>
                        <div className="card-container">
                            <div
                                className="card"
                                style={{
                                    background: getLevelStyle(index).background,
                                }}
                            >
                                {editingLevel?.id === level.id ? (
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        className="edit-form"
                                        initialValues={{
                                            name: level.name,
                                            threshold: level.threshold,
                                            percentOff: level.percentOff
                                        }}
                                    >
                                        <Form.Item
                                            name="name"
                                            label={<span style={{ color: getLevelStyle(index).textColor }}>{translate('Level Name')}</span>}
                                            rules={[{ required: true, message: translate('Please input level name') }]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        {!level.isDefault && (
                                            <Form.Item
                                                name="threshold"
                                                label={<span style={{ color: getLevelStyle(index).textColor }}>{translate('Total consumption')}</span>}
                                                rules={[
                                                    { required: true, message: translate('Please input threshold') },
                                                    { validator: (_, value) => validateThreshold(value) }
                                                ]}
                                                validateTrigger={['onChange', 'onBlur']}
                                            >
                                                <InputNumber
                                                    prefix="$"
                                                    className="w-full"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>
                                        )}

                                        <Form.Item
                                            name="percentOff"
                                            label={<span style={{ color: getLevelStyle(index).textColor }}>{translate('Discount Off')}</span>}
                                            rules={[
                                                { required: true, message: translate('Please input discount') },
                                                { validator: (_, value) => validateDiscount(value) }
                                            ]}
                                            validateTrigger={['onChange', 'onBlur']}
                                        >
                                            <InputNumber
                                                className="w-full"
                                                min={0}
                                                max={100}
                                                step={5}
                                                formatter={value => `${value}% OFF ${getActualDiscount(i18nLanguage, Number(value))}`}
                                                parser={(value: string | undefined) => {
                                                    if (!value) return 0;
                                                    const parsed = Number(value.replace(/% OFF.*$/, ''));
                                                    return isNaN(parsed) ? 0 : parsed;
                                                }}
                                            />
                                        </Form.Item>

                                        <div className="buttons">
                                            <Button onClick={handleCancelEdit}>
                                                {translate('Cancel')}
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={handleSave}
                                                loading={UpdateMemberLevelApiMutation.isLoading}
                                            >
                                                {translate('Save')}
                                            </Button>
                                        </div>
                                    </Form>
                                ) : (
                                    <div className="h-full flex flex-col justify-between">
                                        {/* 添加絕對定位的按鈕容器 */}
                                        <div className="absolute top-4 right-4 flex space-x-2">
                                            <Button
                                                type="primary"
                                                icon={<EditOutlined />}
                                                style={{
                                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                                    borderColor: getLevelStyle(index).textColor,
                                                    color: getLevelStyle(index).textColor,
                                                    width: '32px',
                                                    height: '32px',
                                                    padding: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                onClick={() => handleEdit(level)}
                                            />
                                            {index !== 0 && (
                                                <Button
                                                    danger
                                                    type="primary"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => handleDelete(level.id)}
                                                    loading={DeleteMemberConsumptionLevelApiMutation.isLoading}
                                                    style={{
                                                        width: '32px',
                                                        height: '32px',
                                                        padding: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                />
                                            )}
                                        </div>

                                        <div>
                                            <h3 className="text-3xl font-bold mb-4" style={{ color: getLevelStyle(index).textColor }}>
                                                {level.name}
                                            </h3>
                                            <p className="text-xl mb-2" style={{ color: getLevelStyle(index).textColor }}>
                                                {translate('Total consumption')}: ${(level.isDefault ? 0 : level.threshold).toLocaleString()}
                                            </p>
                                            <p className="text-xl" style={{ color: getLevelStyle(index).textColor }}>
                                                {translate('Store-wide Discount')}: {level.percentOff}% OFF {getActualDiscount(i18nLanguage, level.percentOff)}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* 箭頭指示 */}
                        {index < ((GetMemberLevelsApiMutation.data?.result?.length || 0) - 1) && (
                            <div className="level-arrow">
                                <Space>
                                    <ArrowDownOutlined className="text-lg text-blue-500" />
                                    <Text className="text-blue-500">
                                        +${(GetMemberLevelsApiMutation.data?.result?.[index + 1]?.threshold || 0) -
                                            (index === 0 ? 0 : level.threshold || 0)}
                                    </Text>
                                </Space>
                            </div>
                        )}
                    </React.Fragment>
                ))}

                {/* 新增按鈕和表單放在最後一個等級之後 */}
                <React.Fragment>
                    {/* 添加箭頭指示 - 只在有新的等級金額時顯示差額 */}
                    <div className="level-arrow">
                        <Space>
                            <ArrowDownOutlined className="text-lg text-blue-500" />
                            {showCreateForm && newLevelThreshold && (
                                <Text className="text-blue-500">
                                    +${newLevelThreshold - (
                                        GetMemberLevelsApiMutation.data?.result?.[
                                            GetMemberLevelsApiMutation.data.result.length - 1
                                        ]?.threshold || 0
                                    )}
                                </Text>
                            )}
                        </Space>
                    </div>

                    {/* 新增按鈕或表單 */}
                    {!showCreateForm ? (
                        <div className="card-container">
                            <div
                                className="add-level-button"
                                onClick={handleAddNewLevel}
                            >
                                <div className="text-center">
                                    <div className="add-text">
                                        {translate('Add Next Level')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card-container">
                            <div
                                className="card"
                                style={{
                                    background: getLevelStyle(
                                        (GetMemberLevelsApiMutation.data?.result?.length || 0)
                                    ).background,
                                }}
                            >
                                <Form
                                    form={form}
                                    layout="vertical"
                                    className="edit-form"
                                    onFinish={handleSubmit}
                                    onValuesChange={handleFormValuesChange}
                                >
                                    <Form.Item
                                        name="name"
                                        label={<span style={{ color: getLevelStyle((GetMemberLevelsApiMutation.data?.result?.length || 0)).textColor }}>{translate('Level Name')}</span>}
                                        rules={[{ required: true, message: translate('Please input level name') }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="threshold"
                                        label={<span style={{ color: getLevelStyle((GetMemberLevelsApiMutation.data?.result?.length || 0)).textColor }}>{translate('Total consumption')}</span>}
                                        rules={[
                                            { required: true, message: translate('Please input threshold') },
                                            { validator: (_, value) => validateThreshold(value) }
                                        ]}
                                        validateTrigger={['onChange', 'onBlur']}
                                    >
                                        <InputNumber
                                            prefix="$"
                                            className="w-full"
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="percentOff"
                                        label={<span style={{ color: getLevelStyle((GetMemberLevelsApiMutation.data?.result?.length || 0)).textColor }}>{translate('Discount Off')}</span>}
                                        rules={[
                                            { required: true, message: translate('Please input discount') },
                                            { validator: (_, value) => validateDiscount(value) }
                                        ]}
                                        validateTrigger={['onChange', 'onBlur']}
                                    >
                                        <InputNumber
                                            className="w-full"
                                            min={0}
                                            max={100}
                                            step={5}
                                            formatter={value => `${value}% OFF ${getActualDiscount(i18nLanguage, Number(value))}`}
                                            parser={(value: string | undefined) => {
                                                if (!value) return 0;
                                                const parsed = Number(value.replace(/% OFF.*$/, ''));
                                                return isNaN(parsed) ? 0 : parsed;
                                            }}
                                        />
                                    </Form.Item>

                                    <div className="buttons">
                                        <Button onClick={() => setShowCreateForm(false)}>
                                            {translate('Cancel')}
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => form.submit()}
                                            loading={CreateMemberConsumptionLevelApiMutation.isLoading}
                                        >
                                            {translate('Save')}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            </div>
        </div>
    );
};

export default MemberConsumptionLevelTab; 