import { IAlterMerchantThirdPartyPaymentSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyPaymentSettingViewModel } from "@/interfaces/Responses/Responses";
import { useAlterMerchantThirdPartyPaymentSettingApi, useGetBackOfficeMerchantThirdPartyPaymentSettingViewModelsApi } from "@/lib/api/thirdPartyPayments";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { PlusOutlined } from "@ant-design/icons";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Button, Flex, Modal, Space, Switch, Tag } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import AddPayment, { IAddPaymentSettingRef } from "./AddPayment";
import EditPayment, { IEditPaymentSettingRef } from "./EditPayment";

export interface IPaymentSettingListProps {
}

export interface IPaymentSettingListRef {
}

// 可折疊的行組件
const Row = (props: {
    row: IBackOfficeMerchantThirdPartyPaymentSettingViewModel,
    onToggleDisable: (record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel, isDisable: boolean) => void,
    isLoading: boolean
}) => {
    const [open, setOpen] = useState(false);
    const { translate } = useContext(TranslationContext);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center" style={{ textAlign: 'center' }}>
                    {props.row.providerName}
                </TableCell>
                <TableCell align="center" style={{ textAlign: 'center' }}>
                    <Switch
                        loading={props.isLoading}
                        checked={!props.row.isDisable}
                        onChange={(checked) => props.onToggleDisable(props.row, !checked)}
                        checkedChildren={translate('Enable')}
                        unCheckedChildren={translate('Disable')}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="p-4">
                            <Space direction="vertical" className="w-full">
                                <Flex justify="center" align="center">
                                    <EditPayment
                                        record={props.row}
                                    />
                                </Flex>
                            </Space>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const PaymentList = React.forwardRef((props: IPaymentSettingListProps, ref: React.ForwardedRef<IPaymentSettingListRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const addRef = useRef<IAddPaymentSettingRef>();
    const editRef = useRef<IEditPaymentSettingRef>();
    const abortController = React.useRef(new AbortController());
    const getTagContent = (type) => {
        switch (type) {
            case 'cancel':
                switch (i18nLanguage) {
                    case 'zh_TW':
                        return {
                            title: '支援取消資訊',
                            content: '此訂單可使用原始付款方式設定進行取消。'
                        };
                    case 'en_US':
                    default:
                        return {
                            title: 'Support Cancel Information',
                            content: 'This order can be canceled using the original payment method settings.'
                        };
                };
            case 'refund':
                switch (i18nLanguage) {
                    case 'zh_TW':
                        return {
                            title: '支援退款資訊',
                            content: '退款可使用原始付款方式設定進行處理。退款將根據供應商提供的程序進行處理。'
                        };
                    case 'en_US':
                    default:
                        return {
                            title: 'Support Refund Information',
                            content: 'Refunds can be processed using the original payment method settings. The refund will be processed according to the procedure provided by the vendor.'
                        };
                };
            default:
                return { title: '', content: '' };
        }
    };
    const [merchantThirdPartySettings, setMerchantThirdPartySettings] = useState<IBackOfficeMerchantThirdPartyPaymentSettingViewModel[]>([]);

    const { data, mutate, isLoading } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyPaymentSettingViewModelsApi(abortController.current.signal), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantThirdPartySettings(response.result || []);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: alterMutate, isLoading: alterMutateIsLoading } = useMutation(async (request: IAlterMerchantThirdPartyPaymentSettingRequest) => await useAlterMerchantThirdPartyPaymentSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                refresh();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const handleToggleDisable = (record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel, isDisable: boolean) => {
        alterMutate({
            settingId: record.thirdPartySettingId,
            setting: record.merchantSettings,
            isDisabled: isDisable
        });
    };
    const [openAddSetting, setOpenAddSetting] = useState(false);

    const refresh = () => {
        mutate();
    };

    useEffect(() => {
        abortController.current = new AbortController();
        refresh();
        return () => {
            abortController.current.abort();
        }
    }, []);

    return (
        <div style={{ margin: '16px' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center">{translate('Provider')}</TableCell>
                            <TableCell align="center">{translate('Status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {merchantThirdPartySettings.map((row) => (
                            <Row
                                key={row.id.toString()}
                                row={row}
                                onToggleDisable={handleToggleDisable}
                                isLoading={isLoading || alterMutateIsLoading}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                type="dashed"
                onClick={() => setOpenAddSetting(true)}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: '16px' }}
            >
                {translate('Add new setting')}
            </Button>
            <Modal
                open={openAddSetting}
                afterOpenChange={(open) => {
                    if (open) addRef.current?.refresh();
                }}
                onCancel={() => setOpenAddSetting(false)}
                footer={null}
            >
                <AddPayment
                    ref={addRef}
                    ownRecords={merchantThirdPartySettings}
                    onRefresh={() => refresh()}
                    onClose={() => setOpenAddSetting(false)}
                />
            </Modal>
        </div>
    );
});

export default PaymentList;