import { IChildrenProviderProps } from "@/interfaces/GlobalContextType";
import { INavBarOptionItemSearchModel, INavBarSetting } from "@/interfaces/Responses/Responses";
import { INavBarOption } from "@/Templates/interfaces/templatesInterfaces";
import React, { useEffect, useState } from "react";

export interface IEditNavBarContext {
    navBarSetting: INavBarSetting | undefined;
    setNavBarSetting: React.Dispatch<React.SetStateAction<INavBarSetting | undefined>>;
    navBarOptions: INavBarOption[];
    setNavBarOptions: React.Dispatch<React.SetStateAction<INavBarOption[]>>;
    searchModel: INavBarOptionItemSearchModel | undefined;
    setSearchModel: React.Dispatch<React.SetStateAction<INavBarOptionItemSearchModel | undefined>>;
}
export const PortalSettingPageContext = React.createContext<IEditNavBarContext>(null!);

export const PortalSettingPageContextProvider: React.FC<IChildrenProviderProps> = ({ children }): React.ReactNode => {
    const [navBarSetting, setNavBarSetting] = useState<INavBarSetting>();
    const [navBarOptions, setNavBarOptions] = useState<INavBarOption[]>([]);
    const [searchModel, setSearchModel] = useState<INavBarOptionItemSearchModel>();

    return (
        <PortalSettingPageContext.Provider
            value={
                {
                    navBarSetting,
                    setNavBarSetting,
                    navBarOptions,
                    setNavBarOptions,
                    searchModel,
                    setSearchModel,
                }
            }>
            {children}
        </PortalSettingPageContext.Provider>
    );
}