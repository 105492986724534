import { IItemSpecDto } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from "@/Templates/enums/templateEnums";
import { IItemViewModel, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import { ItemDetailPageTemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useContext, useMemo, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import './ItemDetail.css';
import { useMutation } from "react-query";

export interface ItemDetailProps {
    item: IItemViewModel;
    itemSpecs: IItemSpecDto[];
    isPreview?: boolean;
}
const ItemDetail: React.FC<ItemDetailProps> = ({ item, itemSpecs }) => {
    const {
        merchantId,
        deviceType,
        messageApi,
        merchantPortalStyleSettingsMutation,
        merchantPortalOptionSettingMutation,
        notificationApi
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpecDto | null>(itemSpecs?.[0] || null);
    const [purchaseQuantity, setPurchaseQuantity] = useState(1);
    const portalStyle = merchantPortalStyleSettingsMutation.data?.result?.filter(settings => settings.type === MerchantPortalStyleSettingType.Pages && settings.name === MerchantPortalStyleShopPages.ItemDetailPage)?.[0];
    const handleSpecChange = (value: string) => {
        const spec = itemSpecs.find(spec => spec.id.toString() === value);
        setSelectedItemSpec(spec || null);
        setPurchaseQuantity(1);
    };

    const TemplateComponent = useMemo(() => dynamic<ItemDetailPageTemplateProps>(() =>
        import(`@/Templates/Shop/Pages/ItemDetailPage/Template_${portalStyle?.style || '0'}.tsx`),
        {
            loading: () => <LoadingComponent />,
            ssr: false
        }
    ), []);

    const handleAddToShoppingCartMutation = useMutation(async () => {

    });

    return (
        <TemplateComponent
            navBarHeight={0}
            navBarGap={0}
            BASE_PATH={''}
            merchantId={merchantId || BigInt(0)}
            deviceType={deviceType}
            translate={translate}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
            isPreviewMode={true}
            messageApi={messageApi}
            notificationApi={notificationApi}
            item={item}
            config={portalStyle?.styleSetting || {}}
            itemSpecs={itemSpecs}
            selectedItemSpec={selectedItemSpec}
            purchaseQuantity={purchaseQuantity}
            shoppingCartItemCount={0}
            handleSelectChange={handleSpecChange}
            setPurchaseQuantity={setPurchaseQuantity}
            handleAddToShoppingCartMutation={handleAddToShoppingCartMutation}
        />
    );
}

export default ItemDetail;
