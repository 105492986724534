import { IConfirmMerchantApplyRequest, IQueryMerchantUpgradeLevelOrdersRequest } from "@/interfaces/Requests/Requests";
import { IMerchantApply, IMerchantUpgradeLevelOrder } from "@/interfaces/Responses/SystemManager";
import { IOperationResult, IOperationResultT, IPaginationResponse } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useConfirmMerchatApplyApi = (request: IConfirmMerchantApplyRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/SystemManager/ConfirmMerchatApply`, {
        method: 'POST',
        body: request,
        signal
    });

export const useRejectMerchantApplyApi = (request: IConfirmMerchantApplyRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/SystemManager/RejectMerchantApply`, {
        method: 'POST',
        body: request,
        signal
    });

export const useQueryMerchantUpgradeLevelOrdersApi = (request: IQueryMerchantUpgradeLevelOrdersRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<IPaginationResponse<IMerchantUpgradeLevelOrder[]>>>(`${process.env.BASE_API_URL}/api/v1/SystemManager/QueryMerchantUpgradeLevelOrders`, {
        method: 'GET',
        body: request,
        signal
    });
