import { FrontEndDisplayType } from "@/enums/Enums";
import { InvoiceOrderStatus, InvoviceOrderType, ThirdPartyInvoiceQueryStatusResponseType } from "@/enums/ThirdPartyInvoice";
import { IGetThirdPartyOrdersByPlatformOrderRequest, IQueryThirdPartyOrderStatusRequest } from "@/interfaces/Requests/Requests";
import { IInvalidateThirdPartyOrderRequest, IPrintThirdPartyInvoiceRequest } from "@/interfaces/Requests/ThirdPartyInvoice";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, IOrder } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceOrderViewModel } from "@/interfaces/Responses/ThirdPartyInvoice";
import { useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi, useGetThirdPartyOrdersByPlatformOrderApi, useInvalidateThirdPartyOrderApi, usePrintInvoiceApi, useQueryInvoiceOrderStatusApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ThirdPartyValuesContextProvider } from "@/lib/contexts/ThirdPartyValuesContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { PrinterManager, PrinterService } from "@/Templates/lib/PrinterHelper";
import { UrlHelper } from "@/Templates/lib/UrlHelper";
import { SyncOutlined, WarningTwoTone } from "@ant-design/icons";
import { Button, Card, Descriptions, Empty, Flex, Form, Input, Modal, Select, Tag } from "antd";
import React, { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import InvoiceSelector, { IInvoiceSelectorRef } from "./InvoiceSelector";
import PrinterPopover from "./PrinterPopover";

export interface IInvoiceOrdersProps {
    order: IOrder;
}

export interface IInvoiceOrdersRef {
    onOpen: () => void;
}

const InvoiceOrder = React.forwardRef((props: IInvoiceOrdersProps, ref: React.ForwardedRef<IInvoiceOrdersRef | undefined>) => {
    const urlHelper = new UrlHelper();
    const { merchantId, messageApi, merchantPortalOptionSettingMutation } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const invoiceSelectorRef = React.useRef<IInvoiceSelectorRef>();
    const { data: invoiceOrdersFromApi, mutate: invoiceOrdersFromApiMutate, isLoading } = useMutation(async (reqeust: IGetThirdPartyOrdersByPlatformOrderRequest) => await useGetThirdPartyOrdersByPlatformOrderApi(reqeust));
    const [addinvoiceOrderVisible, setAddinvoiceOrderVisible] = React.useState(false);
    const { data: merchantThirdPartyInvoiceSettingViewModels, mutate: merchantThirdPartyInvoiceSettingViewModelsMutate } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi());
    const { mutate: queryOrderStatusMutate, isLoading: queryOrderStatusLoading } = useMutation(async (reqeust: IQueryThirdPartyOrderStatusRequest) => await useQueryInvoiceOrderStatusApi(reqeust), {
        onSuccess: (response, request) => {
            if (response.isSuccess) {
                messageApi.success(translate("Operation success"));
                switch (response.result?.type) {
                    case ThirdPartyInvoiceQueryStatusResponseType.Normal:
                        invoiceOrdersFromApiMutate({ orderId: props.order.id });
                        break;
                    case ThirdPartyInvoiceQueryStatusResponseType.Redirect:
                        //Redirect to data
                        navigate(response.result?.data);
                        break;
                }
            }
        }
    });
    const printInvoiceMutation = useMutation(async (reqeust: IPrintThirdPartyInvoiceRequest) => await usePrintInvoiceApi(reqeust), {
        onSuccess: async (response, request) => {
            if (!response.isSuccess) {
                messageApi.error(translate(response.message || 'Operation failed'));
                return;
            }

            if (response.result?.type === FrontEndDisplayType.PrinterEscPos) {
                const printerService = new PrinterService();;
                const printerManager = new PrinterManager(printerService);
                await printerManager.print(response.result.data);
            }
        },
        onError: (error: any) => {
            messageApi.error(translate(error.message || 'Operation failed'));
        }
    });
    const invalidateThirdPartyOrderMutation = useMutation(async (reqeust: IInvalidateThirdPartyOrderRequest) => await useInvalidateThirdPartyOrderApi(reqeust), {
        onSuccess: (response, request) => {
            if (response.isSuccess) {
                messageApi.success(translate("Operation success"));
            }
            else
                messageApi.error(translate(response.message || 'Operation failed'));
        },
        onError: (error: any) => {
            messageApi.error(translate(error.message || 'Operation failed'));
        },
        onSettled: (response, error, request) => {
            handleQueryinvoiceOrderStatus(request.thirdPartyOrderId);
        }
    });
    const [selectedInvoiceProvider, setSelectedInvoiceProvider] = React.useState<IBackOfficeMerchantThirdPartyInvoiceSettingViewModel>();
    const getInvoiceOrderStatusColor = (status: string) => {
        switch (status) {
            case 'Created':
            case 'DuringShipping':
            case 'ArrivalWaitForPickup':
                return 'blue'; // Change color based on the status
            case 'Done':
                return 'green';
            default:
                return 'red';
        }
    };
    const handleCreateinvoiceOrder = (thirdPartySettingId: BigInt) => {
        setAddinvoiceOrderVisible(true);
    };
    const handlePrintInvoiceOrder = (invoiceOrder: IThirdPartyInvoiceOrderViewModel) => {
        printInvoiceMutation.mutate({
            thirdPartyOrderId: invoiceOrder.id,
            thirdPartySettingId: invoiceOrder.thirdPartySettingId
        });
    };
    const handleInvalidateThirdPartyOrder = (invoiceOrder: IThirdPartyInvoiceOrderViewModel) => {
        Modal.confirm({
            icon: <WarningTwoTone twoToneColor="red" />,
            title: translate('Are you sure you want to invalidate this invoice order?'),
            onOk: () => {
                invalidateThirdPartyOrderMutation.mutate({
                    thirdPartyOrderId: invoiceOrder.id,
                    thirdPartySettingId: invoiceOrder.thirdPartySettingId
                });
            },
            cancelText: translate('Cancel'),
            okText: translate('Confirm'),
        });
    };
    const handleQueryinvoiceOrderStatus = (thirdPartyinvoiceOrderId: BigInt) => {
        var request: IQueryThirdPartyOrderStatusRequest = {
            thirdPartyOrderId: thirdPartyinvoiceOrderId
        };
        queryOrderStatusMutate(request);
    }
    const getEnabledInvoiceSettings = () => {
        return merchantThirdPartyInvoiceSettingViewModels?.result?.filter(item => item.isDisable === false) || [];
    };
    const sendOrder = (invoiceOrders: IThirdPartyInvoiceOrderViewModel[]) => {
        if (isLoading) {
            return <LoadingComponent></LoadingComponent>
        }
        //desc order by created date
        var invoiceOrder = invoiceOrders
            .filter(order => order.type === InvoviceOrderType.Normal)
            .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())[0];

        if (invoiceOrder) {
            return (
                <Card title={`${translate('Invoice')}`}>
                    <Descriptions column={1}>
                        <Descriptions.Item label={translate('Order ID')}>{invoiceOrder.id.toString()}</Descriptions.Item>
                        <Descriptions.Item label={translate('Provider Name')}>
                            {invoiceOrder.providerName.toString()}
                        </Descriptions.Item>
                        {
                            invoiceOrder.providerOrderId &&
                            <Descriptions.Item label={translate('Invoice.ProviderOrderId')}>
                                {invoiceOrder.providerOrderId.toString()}
                            </Descriptions.Item>
                        }
                        {
                            invoiceOrder.values['InvoiceType'] &&
                            <Descriptions.Item label={translate('Invoice.InvoiceType')}>
                                {translate(`${invoiceOrder.values['InvoiceType']}`)}
                            </Descriptions.Item>
                        }
                        <Descriptions.Item label={translate('Type')}>{translate(`${invoiceOrder.type}`)}</Descriptions.Item>
                        <Descriptions.Item label={translate('Is Success Create From Provider')}>
                            <span style={{ color: invoiceOrder.isSuccessCreateFromProvider ? 'inherit' : 'red' }}>
                                {translate(`${invoiceOrder.isSuccessCreateFromProvider}`)}
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label={translate('Status')}>
                            <Flex align="center" gap="small">
                                <Tag color={getInvoiceOrderStatusColor(invoiceOrder.status)}>
                                    {translate(`Invoice.${invoiceOrder.status}`)}
                                </Tag>
                                <SyncOutlined
                                    className={queryOrderStatusLoading ? "animate-spin" : ""}
                                    onClick={() => handleQueryinvoiceOrderStatus(invoiceOrder.id)}
                                    style={{ cursor: 'pointer', fontSize: '16px', color: '#1890ff' }}
                                />
                            </Flex>
                        </Descriptions.Item>
                        {
                            invoiceOrder.status === InvoiceOrderStatus.Created &&
                            invoiceOrder.values['InvoiceType'] === 'Donate' &&
                            <Descriptions.Item>
                                <Flex style={{ width: '100%' }} justify="center">
                                    <Tag color="green">{translate('Donated')}</Tag>
                                </Flex>
                            </Descriptions.Item>
                        }
                        {
                            invoiceOrder.status === InvoiceOrderStatus.Created &&
                            <Descriptions.Item>
                                <Flex style={{ width: '100%' }} justify="center" gap="middle">
                                    <Button
                                        type="primary"
                                        danger
                                        disabled={isLoading}
                                        onClick={() => handleInvalidateThirdPartyOrder(invoiceOrder)}>
                                        {translate('Invoice.Invalidate')}
                                    </Button>
                                    <Button
                                        disabled={invoiceOrder.values['InvoiceType'] === 'Donate'}
                                        loading={printInvoiceMutation.isLoading}
                                        onClick={() => handlePrintInvoiceOrder(invoiceOrder)}>
                                        <PrinterPopover
                                            displayType={FrontEndDisplayType.PrinterEscPos}
                                            style={{ marginRight: '5px' }} />
                                        {translate('Print')}
                                    </Button>
                                </Flex>
                            </Descriptions.Item>
                        }
                    </Descriptions>
                    {
                        (invoiceOrder.status === InvoiceOrderStatus.UnknowError ||
                            invoiceOrder.status === InvoiceOrderStatus.Invalid ||
                            invoiceOrder.status === InvoiceOrderStatus.NotFound)
                        &&
                        (
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    danger
                                    disabled={isLoading}
                                    onClick={() => handleCreateinvoiceOrder(invoiceOrder.thirdPartySettingId)}>
                                    {translate('Retry Generate')}
                                </Button>
                            </Flex>
                        )
                    }
                </Card >
            );
        }
        else {
            return (
                <Card title={`${translate('Invoice')}`}>
                    {
                        getEnabledInvoiceSettings().length === 0 &&
                        <Empty description={
                            <>
                                {translate('No third party invoice provider is enabled.')}
                                <br />
                                <Link to={`/thirdParty?tab=invoiceSettingList`}>{translate('Click here to configure.')}</Link>
                            </>
                        }></Empty>
                    }
                    {
                        getEnabledInvoiceSettings().length > 0 &&
                        <Form >
                            <Form.Item label={translate('Country')}>
                                <Select disabled value={merchantPortalOptionSettingMutation.data?.result?.country}
                                    onChange={(value) => {
                                        setSelectedInvoiceProvider(getEnabledInvoiceSettings().find(item => item.supportedCountry === value));
                                    }}>
                                    {
                                        Array.from(new Set(
                                            getEnabledInvoiceSettings().map(
                                                item => item.supportedCountry
                                            )
                                        )).map((country, index) => (
                                            <Select.Option value={country} key={index}>
                                                {translate(`Country.${country}`)}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            {
                                Object.entries(props.order.inoviceValues)
                                    .filter(([key, value]) => value !== null && value !== undefined && value !== "")
                                    .map(([key, value], index) => {
                                        return (
                                            <Form.Item label={translate(`Invoice.${key}`)} key={index}>
                                                <Input value={translate(`${value}`)} disabled />
                                            </Form.Item>
                                        );
                                    })
                            }
                            <Flex justify="center">
                                <Button type="primary"
                                    loading={isLoading}
                                    onClick={() => handleCreateinvoiceOrder(props.order.id)}>
                                    {translate('Create Invoice')}
                                </Button>
                            </Flex>
                        </Form>
                    }
                </Card>
            );
        }
    };


    useEffect(() => {
        if (merchantId) {
            var request: IGetThirdPartyOrdersByPlatformOrderRequest = {
                orderId: props.order.id
            };
            invoiceOrdersFromApiMutate(request);
            merchantThirdPartyInvoiceSettingViewModelsMutate();
        }
    }, [merchantId]);

    return (
        <>
            {sendOrder(invoiceOrdersFromApi?.result || [])}
            <Modal
                open={addinvoiceOrderVisible}
                afterOpenChange={(isOpen) => {
                    if (isOpen)
                        (invoiceSelectorRef?.current?.onOpen());
                    else
                        (invoiceSelectorRef?.current?.onClose());
                }}
                onCancel={() => {
                    setAddinvoiceOrderVisible(false);
                }}
                footer={<></>}>
                <>
                    <ThirdPartyValuesContextProvider>
                        <InvoiceSelector
                            ref={invoiceSelectorRef}
                            order={props.order}
                            oninvoiceOrderCreated={() => {
                                var request: IGetThirdPartyOrdersByPlatformOrderRequest = {
                                    orderId: props.order.id
                                };
                                invoiceOrdersFromApiMutate(request);
                                merchantThirdPartyInvoiceSettingViewModelsMutate();
                                setAddinvoiceOrderVisible(false);
                            }}
                        >
                        </InvoiceSelector>
                    </ThirdPartyValuesContextProvider>
                </>
            </Modal>
        </>
    );
});

export default InvoiceOrder;
