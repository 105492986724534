import { IConfirmMerchantApplyRequest as IMerchantApplyRequest } from "@/interfaces/Requests/Requests";
import { IMerchantApply, IMerchantUpgradeLevelOrder } from "@/interfaces/Responses/SystemManager";
import { useConfirmMerchatApplyApi, useQueryMerchantUpgradeLevelOrdersApi, useRejectMerchantApplyApi } from "@/lib/api/systemManager";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Empty, Space, Table } from "antd";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";

const SystemManagePage = () => {
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const useQueryMerchantUpgradeLevelOrdersMutation = useMutation(useQueryMerchantUpgradeLevelOrdersApi);
    useEffect(() => {
        useQueryMerchantUpgradeLevelOrdersMutation.mutate({});
    }, []);
    return <></>;
};

export default SystemManagePage;
