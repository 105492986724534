import { LogisticOrderType } from "@/enums/ThirdPartyLogistic";
import { IBatchCreateThirdPartyLogisticOrderRequest, ICreateThirdPartyLogisticOrderRequest } from "@/interfaces/Requests/ThirdPartyLogistic";
import { IBackOfficeMerchantThirdPartyLogisticSettingViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyLogisticSetting } from "@/interfaces/Responses/ThirdPartyLogistic";
import { useGetLogisticsMainTypesApi } from "@/lib/api/portals";
import { useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi, useGetThirdPartyLogisticsApi } from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { Button, Col, Collapse, Divider, Empty, Flex, Input, Row, Select } from "antd";
import React, { useCallback, useContext, useEffect, useImperativeHandle } from "react";
import { useMutation, useQuery } from "react-query";
const { Panel } = Collapse;

export interface ILogisticSelectorProps {
    order: any;
    limitSettingIds?: BigInt[];
    onLogisticOrderCreated?: (request: IBatchCreateThirdPartyLogisticOrderRequest) => void;
}
export interface ILogisticSelectorRef {
    onOpen: () => void;
    onClose: () => void;
}

const LogisticSelector = React.memo(React.forwardRef((props: ILogisticSelectorProps, ref: React.ForwardedRef<ILogisticSelectorRef | undefined>) => {
    const { translate } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const [mainTypes, setMainTypes] = React.useState<string[]>([]);
    const [merchantThirdPartySettings, setMerchantThirdPartySettings] = React.useState<IBackOfficeMerchantThirdPartyLogisticSettingViewModel[]>([]);
    const [thirdPartySettingsFromApi, setThirdPartySettingsFromApi] = React.useState<IThirdPartyLogisticSetting[]>([]);
    const [thirdPartySettings, setThirdPartySettings] = React.useState<IThirdPartyLogisticSetting[]>([]);
    const [selectedLogistic, setSelectedLogistic] = React.useState<IThirdPartyLogisticSetting | undefined>(undefined);
    const getThirdPartyLogisticsApiMutate = useMutation(useGetThirdPartyLogisticsApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setThirdPartySettingsFromApi(response.result || []);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const getBackOfficeMerchantThirdPartyLogisticSettingViewModelsApiMutate = useMutation(useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantThirdPartySettings(response.result || []);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const getLogisticMainTypeQuery = useQuery('getLogisticsMainTypesApi', useGetLogisticsMainTypesApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMainTypes(response.result || []);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    useImperativeHandle(ref, () => ({
        onOpen() {
            getThirdPartyLogisticsApiMutate.mutate(undefined);
            getBackOfficeMerchantThirdPartyLogisticSettingViewModelsApiMutate.mutate(undefined);
            getLogisticMainTypeQuery.refetch();
        },
        onClose() {
            setSelectedLogistic(undefined);
            setThirdPartySettings([]);
            setMerchantThirdPartySettings([]);
        }
    }), []);

    useEffect(() => {
        if (thirdPartySettingsFromApi.length > 0 && merchantThirdPartySettings.length > 0) {
            const enabledSettings = thirdPartySettingsFromApi.filter(setting =>
                !merchantThirdPartySettings.some(merchantSetting =>
                    merchantSetting.thirdPartySettingId.toString() === setting.id.toString() && merchantSetting.isDisable
                )
            );
            if (props.limitSettingIds && props.limitSettingIds.length > 0) {
                setThirdPartySettings(enabledSettings.filter(setting => props.limitSettingIds!.some(id => id.toString() === setting.id.toString())));
            } else {
                setThirdPartySettings(enabledSettings);
            }
        }
    }, [thirdPartySettingsFromApi, merchantThirdPartySettings, props.limitSettingIds]);

    const handleSubmit = useCallback(() => {
        if (selectedLogistic) {
            const request: IBatchCreateThirdPartyLogisticOrderRequest = {
                requests: [
                    {
                        orderId: props.order.id,
                        settingId: selectedLogistic.id,
                        frontEndValues: {},
                        logisticOrderType: LogisticOrderType.Forward
                    }
                ]
            };
            props.onLogisticOrderCreated && props.onLogisticOrderCreated(request);
        }
    }, [selectedLogistic, props.order.id]);

    const inputParametersUI = useCallback((selectedLogistic: IThirdPartyLogisticSetting) => (
        <>
            {selectedLogistic.merchantFillFieldsForCreateOrder.length > 0 &&
                <Divider>{translate('Parameters')}</Divider>
            }
            {selectedLogistic.merchantFillFieldsForCreateOrder.map((field) => (
                <Row key={field}>
                    <Col span={24}>
                        <label>{field}</label>
                    </Col>
                    <Col span={24}>
                        <Input />
                    </Col>
                </Row>
            ))}
            {Object.keys(selectedLogistic.merchantSelectFieldsForCreateOrder).map((key) => (
                <Row key={key}>
                    <Col span={24}>
                        <label>{key}</label>
                    </Col>
                    <Col span={24}>
                        <Select>
                            {selectedLogistic.merchantSelectFieldsForCreateOrder[key].map((value: string) => (
                                <Select.Option key={value} value={value}>{value}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            ))}
        </>
    ), [translate]);

    if (getThirdPartyLogisticsApiMutate.isLoading || getBackOfficeMerchantThirdPartyLogisticSettingViewModelsApiMutate.isLoading || getLogisticMainTypeQuery.isLoading) {
        return <LoadingComponent />;
    }
    return (
        <Flex justify={'center'}>
            <Row style={{ width: '80%' }}>
                {thirdPartySettings.length > 0 ? (
                    <>
                        <Divider>{translate('Type')}</Divider>
                        <Col span={24}>
                            <Collapse>
                                {mainTypes.filter(type =>
                                    thirdPartySettings.some(logistic => logistic.logisticsMainType === type) && type !== 'None'
                                ).map((mainType) => (
                                    <Panel header={translate(mainType)} key={mainType}>
                                        <Divider>{translate('Providers')}</Divider>
                                        <Flex gap="16px" justify="center">
                                            {thirdPartySettings.filter(logistic => logistic.logisticsMainType === mainType).map((logistic) => (
                                                <div key={logistic.id.toString()}>
                                                    <Button
                                                        type={logistic === selectedLogistic ? 'primary' : 'default'}
                                                        onClick={() => setSelectedLogistic(logistic === selectedLogistic ? undefined : logistic)}
                                                    >
                                                        {logistic.providerName}
                                                    </Button>
                                                </div>
                                            ))}
                                        </Flex>
                                    </Panel>
                                ))}
                            </Collapse>
                        </Col>
                    </>
                ) : (
                    <Col span={24}>
                        <Flex justify="center">
                            <Empty description={translate('No available logistics settings')} />
                        </Flex>
                    </Col>
                )}
                {selectedLogistic && (
                    <>
                        <Col span={24}>
                            {inputParametersUI(selectedLogistic)}
                            <Flex style={{ margin: '10px' }} justify="center" align="center">
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                >
                                    {translate('Submit')}
                                </Button>
                            </Flex>
                        </Col>
                    </>
                )}
            </Row>
        </Flex>
    );
}));

export default LogisticSelector;
