import { IAlterItemIsOnShelfRequest, IDeleteItemRequest } from '@/interfaces/Requests/Requests';
import { useAlterItemIsOnShelfApi, useDeleteItemApi, useGetItemsByMerchantApi } from '@/lib/api/items';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { IItemViewModel } from '@/Templates/interfaces/templatesInterfaces';
import type { ProColumns, ProFormInstance } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Button, Empty, Flex, Image, Modal, Select, Space, Switch, Tooltip, InputNumber, Radio } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { IBackOfficeItemViewModel } from '@/interfaces/Responses/Responses';
import { IAlterItemSpecStockAmountRequest } from '@/interfaces/Requests/Requests';
import { useAddItemSpecStockApi, useReduceItemSpecStockApi, useSetItemSpecStockApi } from '@/lib/api/items';
import { EditOutlined } from '@ant-design/icons';

const ItemManagePage: React.FC = () => {
    const { deviceType, merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [searchCollapsed, setSearchCollapsed] = useState<boolean>(true);
    const formRef = useRef<ProFormInstance>();
    const abortController = useRef<AbortController | null>(null);
    const [isStockModalVisible, setIsStockModalVisible] = useState(false);
    const [selectedSpec, setSelectedSpec] = useState<{ id: BigInt, name: string, stockAmount: number } | null>(null);
    const [stockOperation, setStockOperation] = useState<'add' | 'reduce' | 'set'>('add');
    const stockAmountRef = useRef<number>(0);
    const [lastExpandedId, setLastExpandedId] = useState<BigInt | undefined>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const { mutate: alterOnShelfMutate, isLoading: alterOnShelfMutateIsLoading } = useMutation(
        async (request: IAlterItemIsOnShelfRequest) => await useAlterItemIsOnShelfApi(request),
        {
            onSuccess: (response, request) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    // alter the switch UI status in the table
                    const data = getItemsByMerchantMutation.data;
                    if (data) {
                        const item = data.result?.data.find((item) => item.id === request.itemId);
                        if (item) {
                            item.isOnShelf = request.isOnShelf;
                        }
                    }
                }
                else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const deleteItemMutation = useMutation(
        async (request: IDeleteItemRequest) => await useDeleteItemApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    formRef.current?.submit();
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const getItemsByMerchantMutation = useMutation(
        async (params: any) => {
            const response = await useGetItemsByMerchantApi({
                merchantId: merchantId || BigInt(0),
                page: params.current || 1,
                pageSize: params.pageSize || 10,
                fuzzingName: params.fuzzingName,
                categoryIds: params.categoryIds,
                extraItemIds: params.extraItemIds,
                extraItemTagIds: params.extraItemTagIds,
                extraOperationInclude: params.extraOperationInclude,
                isOrderByDesc: params.isOrderByDesc,
                isOnShelf: params.isOnShelf,
            }, abortController.current?.signal);
            return response;
        },
        {
            onSuccess: (response, variables, context) => {
                if (!response.isSuccess) {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const addStockMutation = useMutation({
        mutationFn: (request: IAlterItemSpecStockAmountRequest) => useAddItemSpecStockApi(request),
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                formRef.current?.submit();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const reduceStockMutation = useMutation({
        mutationFn: (request: IAlterItemSpecStockAmountRequest) => useReduceItemSpecStockApi(request),
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                formRef.current?.submit();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const setStockMutation = useMutation({
        mutationFn: (request: IAlterItemSpecStockAmountRequest) => useSetItemSpecStockApi(request),
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                formRef.current?.submit();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const handleDelete = (itemId: BigInt) => {
        Modal.confirm({
            title: translate('Are you sure to delete') + '?',
            okText: translate('Yes'),
            onOk: () => deleteItemMutation.mutate({ itemId }),
            cancelText: translate('No'),
        });
    };

    const handleIsOnShelfChange = (itemId: BigInt, checked: boolean) => {
        alterOnShelfMutate({ itemId, isOnShelf: checked });
    };

    const handleStockOperation = () => {
        if (!selectedSpec) return;

        const request: IAlterItemSpecStockAmountRequest = {
            itemSpecId: selectedSpec.id,
            amount: stockAmountRef.current
        };

        switch (stockOperation) {
            case 'add':
                addStockMutation.mutate(request);
                break;
            case 'reduce':
                reduceStockMutation.mutate(request);
                break;
            case 'set':
                setStockMutation.mutate(request);
                break;
        }
    };

    const columns: ProColumns<IBackOfficeItemViewModel>[] = [
        {
            title: translate('Name'),
            dataIndex: "name",
            width: 'auto',
            align: 'center',
            ellipsis: true,
            render: (_, record) => (
                <Button type="link" onClick={() => navigate(`/editItem?itemId=${record.id}&previewOnly=${record.isOnShelf}`)}>{record.name}</Button>
            ),
            fixed: 'left',
            search: {
                transform: (value) => ({ fuzzingName: value }),
            },
        },
        {
            title: translate('Image'),
            dataIndex: "coverImages",
            width: 'auto',
            align: 'center',
            hideInSearch: true,
            ellipsis: true,
            render: (_, record) => (
                <Space>
                    {
                        record.coverImages?.length === 0 &&
                        <Empty description={translate('No Data')}></Empty>
                    }
                    {record.coverImages?.map((url: string) => (
                        <LazyLoadImage
                            effect="blur"
                            key={url}
                            width={80}
                            height={80}
                            src={url}
                            preview={{
                                mask: translate("Preview"),
                            }}
                        />
                    ))}
                </Space>
            )
        },
        {
            title: translate('IsOnShelf'),
            dataIndex: "isOnShelf",
            width: 'auto',
            align: 'center',
            renderFormItem(schema, config, form, action) {
                return (
                    <Select allowClear>
                        <Select.Option value={true}>{translate('Yes')}</Select.Option>
                        <Select.Option value={false}>{translate('No')}</Select.Option>
                    </Select>
                );
            },
            render: (_, record) => (
                <Switch
                    style={{ backgroundColor: record.isOnShelf ? 'green' : 'gray' }}
                    loading={alterOnShelfMutateIsLoading}
                    checked={record.isOnShelf}
                    checkedChildren={translate('On Shelf')}
                    unCheckedChildren={translate('Off Shelf')}
                    onChange={(checked) => handleIsOnShelfChange(record.id, checked)}
                />
            ),
        },
    ];

    // 庫存操作 Modal
    const StockModal = () => (
        <Modal
            title={translate('Edit')}
            open={isStockModalVisible}
            onCancel={() => {
                setIsStockModalVisible(false);
                stockAmountRef.current = 0;
            }}
            footer={[
                <Button
                    key="cancel"
                    onClick={() => {
                        setIsStockModalVisible(false);
                        stockAmountRef.current = 0;
                    }}
                >
                    {translate('Cancel')}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={addStockMutation.isLoading || reduceStockMutation.isLoading || setStockMutation.isLoading}
                    onClick={() => {
                        handleStockOperation();
                        setIsStockModalVisible(false);
                        stockAmountRef.current = 0;
                    }}
                >
                    {translate('Confirm')}
                </Button>
            ]}
        >
            <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 8 }}>
                    {translate('Current Stock')}: {selectedSpec?.stockAmount}
                </div>
                <Radio.Group
                    value={stockOperation}
                    onChange={(e) => setStockOperation(e.target.value)}
                    style={{ marginBottom: 16 }}
                >
                    <Radio.Button value="add">{translate('Increase')}</Radio.Button>
                    <Radio.Button value="reduce">{translate('Reduce')}</Radio.Button>
                    <Radio.Button value="set">{translate('Set to')}</Radio.Button>
                </Radio.Group>
                <InputNumber
                    min={0}
                    defaultValue={0}
                    onChange={(value) => {
                        if (value !== null) {
                            stockAmountRef.current = value;
                        }
                    }}
                    style={{ width: '100%' }}
                    placeholder={translate('Enter amount')}
                />
            </div>
        </Modal>
    );

    // 修改處理邏輯，檢查所有展開項目
    const handleRequestSuccess = (data: IBackOfficeItemViewModel[]) => {
        if (expandedRowKeys.length > 0) {
            // 過濾出仍然存在的項目ID
            const stillExistingItems = expandedRowKeys.filter(expandedKey =>
                data.some(item => item.id.toString() === expandedKey)
            );

            // 更新展開狀態
            setExpandedRowKeys(stillExistingItems);
        }
    };

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current?.abort();
        }
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <StockModal />
            <ProTable<IBackOfficeItemViewModel>
                columns={columns}
                formRef={formRef}
                ghost={true}
                cardBordered
                scroll={{ x: 'max-content' }}
                sticky={{ offsetHeader: 0 }}
                onReset={() => setSearchCollapsed(true)}
                search={{
                    labelWidth: 'auto',
                    collapsed: searchCollapsed,
                    onCollapse: setSearchCollapsed,
                    defaultColsNumber: 2,
                    span: {
                        xs: 24,
                        sm: 24,
                        md: 12,
                        lg: 8,
                        xl: 6,
                        xxl: 6,
                    },
                    optionRender: (searchConfig, formProps, dom) => [...dom.reverse()],
                }}
                request={async (params, sorter, filter) => {
                    const { result, isSuccess, message } = await getItemsByMerchantMutation.mutateAsync({
                        ...params,
                        fuzzingName: params.fuzzingName,
                    });
                    if (!isSuccess) {
                        messageApi.error(translate(message || 'Operation failed'));
                        return {
                            data: [],
                            success: false,
                        };
                    }
                    // 在數據加載成功後檢查展開狀態
                    handleRequestSuccess(result?.data || []);
                    return {
                        data: result?.data || [],
                        success: true,
                        total: result?.totalCount || 0,
                    };
                }}
                editable={{
                    type: 'multiple',
                }}
                rowKey={(record) => record.id.toString()}
                toolBarRender={() => [
                    <Button
                        key="add"
                        type="primary"
                        onClick={() => navigate('/addItem')}
                    >
                        {translate('Add Item')}
                    </Button>
                ]}
                pagination={{
                    pageSize: 10,
                    showQuickJumper: true,
                }}
                dateFormatter="string"
                expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpandedRowsChange: (expandedRows) => {
                        setExpandedRowKeys(expandedRows as string[]);
                    },
                    expandedRowRender: (record) => (
                        <div style={{ padding: '16px 24px', background: '#fafafa' }}>
                            {record.itemSpecs.map((spec) => (
                                <div key={spec.id.toString()} style={{
                                    marginBottom: 16,
                                    padding: 20,
                                    background: '#fff',
                                    borderRadius: 8,
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.06)'
                                }}>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                        gap: 32
                                    }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: 8 }}>
                                                {spec.name}
                                            </div>
                                            <div style={{ display: 'flex', gap: 8 }}>
                                                <span style={{ color: '#666', width: 80 }}>售價:</span>
                                                <span>{spec.sellPrice} {spec.currency}</span>
                                            </div>
                                            <div style={{ display: 'flex', gap: 8 }}>
                                                <span style={{ color: '#666', width: 80 }}>定價:</span>
                                                <span>{spec.fixedPrice}</span>
                                            </div>
                                            {spec.isManageStockAmount && (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                                    <span style={{ color: '#666', width: 80 }}>{translate('Stock')}:</span>
                                                    <span>{spec.stockAmount}</span>
                                                    <Button
                                                        type="link"
                                                        icon={<EditOutlined />}
                                                        onClick={() => {
                                                            setSelectedSpec({
                                                                id: spec.id,
                                                                name: spec.name,
                                                                stockAmount: spec.stockAmount
                                                            });
                                                            setIsStockModalVisible(true);
                                                        }}
                                                    >
                                                        {translate('Edit')}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                                            <div style={{ display: 'flex', gap: 8 }}>
                                                <span style={{ color: '#666', width: 80 }}>尺寸:</span>
                                                <span>{spec.length}x{spec.width}x{spec.height} cm</span>
                                            </div>
                                            <div style={{ display: 'flex', gap: 8 }}>
                                                <span style={{ color: '#666', width: 80 }}>重量:</span>
                                                <span>{spec.weight} kg</span>
                                            </div>
                                            <div style={{ display: 'flex', gap: 8 }}>
                                                <span style={{ color: '#666', width: 80 }}>備註:</span>
                                                <span>{spec.note || '無'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <Flex justify='center' gap={16}>
                                <Tooltip key="edit" title={record.isOnShelf ? translate("Need to be off shelf") : ""}>
                                    <Button
                                        type="primary"
                                        disabled={record.isOnShelf}
                                        onClick={() => navigate(`/editItem?itemId=${record.id}`)}
                                    >
                                        {translate('Edit')}
                                    </Button>
                                </Tooltip>
                                <Tooltip key="delete" title={record.isOnShelf ? translate("Need to be off shelf") : ""}>
                                    <Button
                                        danger
                                        loading={deleteItemMutation.isLoading}
                                        type="primary"
                                        disabled={record.isOnShelf}
                                        onClick={() => handleDelete(record.id)}
                                    >
                                        {translate('Delete')}
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </div>
                    ),
                    rowExpandable: (record) => record.itemSpecs?.length > 0
                }}
            />
        </div>
    );
};

export default ItemManagePage;