export class GenerateHelper {
    static generateSnowFlakeBigInt() {
        const EPOCH = 1577836800000n; // 2020-01-01 UTC
        let sequence = 0n;
        let lastTimestamp = 0n;

        const timestamp = BigInt(Date.now());

        if (timestamp === lastTimestamp) {
            sequence = (sequence + 1n) & 4095n;
            if (sequence === 0n) {
                while (Date.now() <= Number(timestamp)) { }
            }
        } else {
            sequence = 0n;
        }

        lastTimestamp = timestamp;

        return ((timestamp - EPOCH) << 22n) | // 时间戳 41位
            (5n << 17n) |                // 数据中心ID 5位 (固定值5)
            (5n << 12n) |                // 机器ID 5位 (固定值5)
            sequence;                    // 序列号 12位
    }
}


