import React, { useState, FC, useEffect } from 'react';
import './DeepSeekChat.css';
import { Form, Input, Button, Select, Alert, Typography } from 'antd';
import useAIService from '@/lib/services/useAIService';

interface DeepSeekChatProps {
    hideLayout?: boolean;
    apiKey?: string;
    model?: string;
    prompt?: string;
    systemMessage?: string;
    /**
     * 持續回應的回調函數
     * @param response 回應內容Event
     */
    onResponseStreamReceived?: (response: string) => void;
}

const DeepSeekChat: FC<DeepSeekChatProps> = ({
    hideLayout = false,
    apiKey: initialApiKey = '',
    model: initialModel = 'deepseek-chat',
    prompt: initialPrompt = '',
    systemMessage: initialSystemMessage = '你是一個有用的助手',
    onResponseStreamReceived
}) => {
    const [localApiKey, setLocalApiKey] = useState(initialApiKey);
    const [localModel, setLocalModel] = useState(initialModel);
    const [localPrompt, setLocalPrompt] = useState(initialPrompt);
    const [localSystemMessage, setLocalSystemMessage] = useState(initialSystemMessage);
    const [selectedTemplate, setSelectedTemplate] = useState('ai-flow');
    const [customSystemMessage, setCustomSystemMessage] = useState('');

    const models = [
        'deepseek-chat',
        'deepseek-coder',
        'deepseek-math'
    ];

    const promptTemplates = {
        'ai-flow': 'AI Flow 設計提示詞（預設）',
        'default': '標準助手提示詞',
        'custom': '自定義提示詞'
    };

    const systemPrompts: Record<string, string> = {
        'ai-flow': `你是一個專業的流程圖設計助理，需要根據用戶需求生成符合 React Flow 格式規範的 JSON 數據。請嚴格按照以下規則處理：

# 輸入格式
用戶會用自然語言描述流程圖需求，包含：
1. 流程步驟結構
2. 節點類型要求（基礎/媒體/便利貼）
3. 特殊布局需求

# 輸出要求
必須返回嚴格符合此結構的 JSON：
{
  "nodes": [/* 節點數組 */],
  "edges": [/* 連線數組 */],
  "viewport": {/* 視圖配置 */}
}

# 處理規則
1. 節點類型對應：
   - 開始節點 → type: "input"
   - 普通處理節點 → type: "default"
   - 便利貼 → type: "sticky"
2. 自動生成合理佈局
3. 唯一ID格式：類型_序號
4. 預設視圖配置：viewport: {x: 100, y: 100, zoom: 1}
5. (重要)只返回純 JSON 數據，不要包含任何其他說明文字`,

        'default': '你是一個有用的助手'
    };

    useEffect(() => {
        if (selectedTemplate !== 'custom') {
            setLocalSystemMessage(systemPrompts[selectedTemplate]);
        }
    }, [selectedTemplate]);

    const {
        loading,
        error,
        response: aiServiceResponse,
        sendRequest
    } = useAIService({
        apiKey: localApiKey,
        model: localModel,
        systemMessage: localSystemMessage || initialSystemMessage
    });

    const handleSubmit = async (e: React.FormEvent) => {
        try {
            await sendRequest(localPrompt || initialPrompt);
        } catch (err) {
            console.error('錯誤處理:', err);
        }
    };

    return (
        <div className="deepseek-chat-container" style={{ display: hideLayout ? 'none' : 'block' }}>
            <Form onFinish={handleSubmit} layout="vertical" className="chat-form">
                <Form.Item label="API 金鑰：">
                    <Input.Password
                        value={localApiKey}
                        onChange={(e) => setLocalApiKey(e.target.value)}
                        placeholder="輸入您的API金鑰"
                    />
                </Form.Item>

                <Form.Item label="選擇模型：">
                    <Select
                        value={localModel}
                        onChange={(value) => setLocalModel(value)}
                    >
                        {models.map((model) => (
                            <Select.Option key={model} value={model}>
                                {model}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="提示詞模板：">
                    <Select
                        value={selectedTemplate}
                        onChange={(value) => setSelectedTemplate(value)}
                    >
                        {Object.entries(promptTemplates).map(([value, label]) => (
                            <Select.Option key={value} value={value}>{label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedTemplate === 'custom' && (
                    <Form.Item label="自定義系統提示詞：">
                        <Input.TextArea
                            value={localSystemMessage}
                            onChange={(e) => setLocalSystemMessage(e.target.value)}
                            rows={8}
                            placeholder="輸入自定義系統提示詞"
                        />
                    </Form.Item>
                )}

                <Form.Item label="用戶輸入：" required>
                    <Input.TextArea
                        value={localPrompt}
                        onChange={(e) => setLocalPrompt(e.target.value)}
                        rows={5}
                        placeholder="輸入您的提示詞"
                        required
                    />
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                >
                    {loading ? '發送中...' : '發送請求'}
                </Button>
            </Form>

            {error && (
                <Alert
                    message="錯誤"
                    description={error}
                    type="error"
                    showIcon
                    style={{ marginTop: 16 }}
                />
            )}

            {aiServiceResponse && (
                <div className="response-container" style={{ marginTop: 16 }}>
                    <Typography.Title level={4}>回應內容：</Typography.Title>
                    <Typography.Text>
                        <pre style={{
                            background: '#f5f5f5',
                            padding: 16,
                            borderRadius: 4,
                            whiteSpace: 'pre-wrap'
                        }}>{aiServiceResponse}</pre>
                    </Typography.Text>
                </div>
            )}
        </div>
    );
};

export default DeepSeekChat;
